'use strict';

/**
 * Display shipping address as selected billing address option
 */
function displayShippingAddressAsSelected() {
	const billingAddressSelector = document.getElementById(
		'billingAddressSelector'
	);

	if (billingAddressSelector) {
		const shipmentIndex = 2;
		const shippingAddressToSelect =
			billingAddressSelector.children[shipmentIndex];

		if (shippingAddressToSelect) {
			shippingAddressToSelect.selected = true;
		}
	}
}

module.exports = {
	displayShippingAddressAsSelected,
};

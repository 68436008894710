'use strict';

const helper = require('../../helper');
const creditCardPaymentProcessingHelper = require('../helpers/creditCardPaymentProcessingHelper');
const creditCardHelper = require('../helpers/creditCardHelper');
const creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');
const creditCardSessionAccountHelper = require('../helpers/creditCardSessionAccountHelper');

/**
 * Update Session account
 * @param {Object} sessionOption - session option
 */
function clearSessionAccount(sessionOption) {
	sessionOption.selected = false;
	sessionOption.classList.add('used-creditcard-account-hide');
	sessionOption.classList.remove('used-creditcard-account');
	sessionOption.text = '';
	sessionOption.setAttribute('data-session-account', false);
	sessionOption.setAttribute('data-owner', false);
	sessionOption.setAttribute('data-expiration', false);
	sessionOption.setAttribute('data-number', false);
	sessionOption.setAttribute('data-nonce', false);
	sessionOption.setAttribute('data-type', false);
	sessionOption.setAttribute('data-save-card', false);
}

/**
 * Remove Credit Card session account
 */
function removeSessionNonce() {
	const defaultCreditCard = helper.getOptionByDataDefault(
		'#braintreeCreditCardList'
	);
	const creditCardFieldstoDisplay =
		creditCardFormFieldHelper.getCCFieldsToDisplay();
	const creditCardFieldsAsArray =
		creditCardFormFieldHelper.getCCFields().asArray;
	const ccCvvToDisplayField =
		creditCardFormFieldHelper.getCcCvvToDisplayField().asArray;
	const ccCvvField = creditCardFormFieldHelper.getCcCvvField().asArray;
	const sessionOption = helper.getSessionAccountOption({
		querySelector: '#braintreeCreditCardList',
		id: 'braintreeSessionCreditAccount',
	});

	if (!sessionOption) {
		return;
	}

	const sessionLastFour = sessionOption.getAttribute('data-last-four');
	const sessionType = sessionOption.getAttribute('data-type').toLowerCase();

	clearSessionAccount(sessionOption);

	// Handles case when stored and session credit card have the same type and last four digest
	// Makes visable already stored Credit Card in Credit Card List
	creditCardPaymentProcessingHelper.showAlreadyStoredCard(
		sessionLastFour,
		sessionType
	);

	creditCardSessionAccountHelper.clearCreditCardFields();
	creditCardSessionAccountHelper.clearCreditCardNonceField();

	if (defaultCreditCard) {
		defaultCreditCard.selected = true;

		creditCardFormFieldHelper.setCreditCardFieldsToDisplay(
			defaultCreditCard,
			creditCardFieldstoDisplay.asObject
		);

		// Uncheck Save Credit Card checkbox
		creditCardHelper.saveCreditCard(false);
		creditCardHelper.hideSaveCreditCardBlock(true);
	} else {
		creditCardFormFieldHelper.hideCardElements(
			creditCardFieldstoDisplay.asArray.concat(ccCvvToDisplayField)
		);
		creditCardFormFieldHelper.showCardElements(
			creditCardFieldsAsArray.concat(ccCvvField)
		);

		// Hides 'Select a Credit Card block' on the Billing Page
		const ccAccount = document.querySelector(
			'.js-braintree-used-creditcard-account'
		);

		ccAccount.classList.remove('used-creditcard-account');
		ccAccount.classList.add('used-creditcard-account-hide');
	}
}

module.exports = {
	removeSessionNonce,
};

'use strict';

/**
 * Display billing address of passed payment option (of selected Credit Card payment option)
 * @param {Object} $selectedCreditCardPayment Selected PayPal payment option
 */
function displaySelectedStoredBillingAddress($selectedCreditCardPayment) {
	const paymentMethodGeneralHelper = require('../../paymentMethodGeneralHelper');

	if ($selectedCreditCardPayment) {
		const selectedOptionClassList = $selectedCreditCardPayment.classList;
		const storedCcAddressClassName = 'js-stored-credit-card-address';
		const $storedCreditCardAddressOption = document.querySelector(
			`.${storedCcAddressClassName}`
		);

		if (selectedOptionClassList.contains('js-stored-card')) {
			const billingAddressAsString =
				paymentMethodGeneralHelper.getBillingAddressAsString(
					$selectedCreditCardPayment
				);

			if (billingAddressAsString !== '') {
				if ($storedCreditCardAddressOption) {
					$storedCreditCardAddressOption.innerText = billingAddressAsString;
					$storedCreditCardAddressOption.selected = true;
				} else {
					const option = document.createElement('option');

					option.innerHTML = billingAddressAsString;
					option.selected = true;
					option.classList.add(storedCcAddressClassName);

					document.getElementById('billingAddressSelector').append(option);
				}
			}
		} else if ($storedCreditCardAddressOption) {
			$storedCreditCardAddressOption.selected = false;
		}
	}
}

/**
 * Shows or hides a billing info alert for the credit cart payment method
 * @param {Object} savedBillingAddress A billing Address object
 */
function initBillingInfoAlert(savedBillingAddress) {
	const $creditCardBillingInfoAlert = document.querySelector(
		'.js-creditcard-billing-address-info-alert'
	);
	// Shows or hides a billing info alert for Credit card payment

	if (savedBillingAddress) {
		$creditCardBillingInfoAlert.style.display = 'block';
	} else {
		$creditCardBillingInfoAlert.style.display = 'none';
	}
}

/**
 * Creates a valid billing address for Credit card payment method
 * @returns {Object} A billing address object
 */
function createCreditCardBillingAddress() {
	const helper = require('../../helper');

	const billingData = helper.getBillingAddressFormValues();

	return {
		firstName: billingData.firstName,
		lastName: billingData.lastName,
		address1: decodeURIComponent(billingData.address1),
		address2: decodeURIComponent(billingData.address2),
		city: decodeURIComponent(billingData.city),
		stateCode: billingData.stateCode,
		postalCode: decodeURIComponent(billingData.postalCode),
		country: decodeURIComponent(billingData.country),
		phone: billingData.phone,
	};
}

/**
 * Fills a credit card billing address input the with billing address data
 * @param {Object} billingAddress A billing address object
 */
function setCreditCardBillingAddress(billingAddress) {
	document.getElementById('braintreeCardBillingAddress').value =
		JSON.stringify(billingAddress);
}

module.exports = {
	initBillingInfoAlert,
	createCreditCardBillingAddress,
	setCreditCardBillingAddress,
	displaySelectedStoredBillingAddress,
};

'use strict';

/**
 * Inits APMA functionality
 */
function init() {
	const payPalBusinessLogic = require('./paypal/payPal');
	const paymentMethodGeneral = require('./paymentMethodGeneral');

	payPalBusinessLogic.init(
		paymentMethodGeneral.createGeneralClientInstancePromise()
	);
	payPalBusinessLogic.initAPMA(
		paymentMethodGeneral.createGeneralClientInstancePromise()
	);
}

module.exports = {
	init,
};

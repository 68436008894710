'use strict';

/**
 * Display billing address of passed paypal payment option (of selected paypal payment option)
 * @param {Object} selectedPayPalPayment Selected PayPal payment option
 */
function displaySelectedStoredBillingAddress(selectedPayPalPayment) {
	const paymentMethodGeneralHelper = require('../../../braintree/paymentMethodGeneralHelper');

	if (selectedPayPalPayment) {
		const selectedOptionClassList = selectedPayPalPayment.classList;
		const storedPayPalAddressOption = document.querySelector(
			'.stored-billing-address'
		);

		let billingAddressAsString;

		if (selectedOptionClassList.contains('js-new-paypal-account')) {
			billingAddressAsString = selectedPayPalPayment.getAttribute(
				'data-billing-address'
			);
		} else {
			billingAddressAsString =
				paymentMethodGeneralHelper.getBillingAddressAsString(
					selectedPayPalPayment
				);
		}

		if (billingAddressAsString) {
			if (storedPayPalAddressOption) {
				storedPayPalAddressOption.text = billingAddressAsString;
				storedPayPalAddressOption.selected = true;
			} else {
				paymentMethodGeneralHelper.createBillingAddressOption(
					'stored-billing-address',
					billingAddressAsString
				);
			}
		}
	}
}

module.exports = {
	displaySelectedStoredBillingAddress,
};

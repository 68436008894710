'use strict';

// Helpers
const helper = require('../../helper');
const srcListHelper = require('../helpers/srcListHelper');
const srcHelper = require('../helpers/srcHelper');

const $srcSessionAccount = document.getElementById('sessionSrcAccount');
const $srcCardDescription = document.getElementById(
	'braintreeSrcCardDescription'
);
const $braintreeSrcCardType = document.getElementById('braintreeSrcCardType');
const $braintreeSrcLastFourDigits = document.getElementById(
	'braintreeSrcLastFourDigits'
);
const $braintreeSrcCreditCardNumber = document.getElementById(
	'braintreeSrcCreditCardNumber'
);
const $srcButton = document.querySelector('.js-braintree-src-button');
const $accountListBlock = document.querySelector(
	'.js-braintree-used-src-account'
);
/**
 * Shows used session account(card) in dropdown and handles checkout view
 * @param {Object} srcPayload payload with card data from tokenization
 */

function showSrcSessionAccount(srcPayload) {
	// description of used card(ex. Visa ending in 1111)
	const cardDescription = `${srcPayload.details.cardType} ${srcPayload.description}`;

	$srcSessionAccount.value = cardDescription;
	$srcSessionAccount.text = cardDescription;
	$srcCardDescription.value = cardDescription;
	$srcSessionAccount.selected = true;
	$accountListBlock.style.display = 'block';
	$srcButton.classList.remove('d-flex');
	$srcButton.classList.add('d-none');

	// Inputs required for identifying already saved cards
	$braintreeSrcCardType.value = srcPayload.details.cardType;
	$braintreeSrcLastFourDigits.value = srcPayload.details.lastFour;
	$braintreeSrcCreditCardNumber.value =
		'************' + srcPayload.details.lastFour;

	srcListHelper.handleSaveSrcAccountCheckbox($srcSessionAccount, true);
	srcListHelper.setIsHideContinueButtonAttr(false);
	helper.continueButtonToggle(false);
}

/*
 * Removes method was used and changes appearance of SRC tab
 * */
function removeSessionNonce() {
	if ($srcSessionAccount) {
		$srcSessionAccount.value = '';
		$srcSessionAccount.text = '';
		$srcSessionAccount.setAttribute('data-save', false);
	}

	$accountListBlock.style.display = 'none';

	document.getElementById('braintreeSRCNonce').value = '';
	document.getElementById('braintreeSrcBillingAddress').value = '';
	document.getElementById('braintreeSrcCardDescription').value = '';
	document.getElementById('newSrcAccount').selected = true;

	srcHelper.srcContentTabHandler(false);
}

module.exports = {
	showSrcSessionAccount,
	removeSessionNonce,
};

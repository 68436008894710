'use strict';

const PayPalBaseModel = require('./payPalBaseModel');
const helper = require('../../helper');

function PayPalAccountModel(
	alertHandlerModel,
	btClientInstancePromise,
	payPalButtonSelector,
	payPalConfigurations,
	$csrfToken,
	addPayPalAccountUrl,
	$loaderContainer
) {
	PayPalBaseModel.call(
		this,
		alertHandlerModel,
		btClientInstancePromise,
		payPalButtonSelector,
		payPalConfigurations,
		$loaderContainer
	);

	this.$csrfToken = $csrfToken;
	this.addPayPalAccountUrl = addPayPalAccountUrl;
}

// ES5 inheritance
PayPalAccountModel.prototype = Object.create(PayPalBaseModel.prototype);

/**
 * For whole description open "payPalBaseModel".
 * We don't generate and send fraud data from Account. That's why "generateFraudData" isn't used
 * @param {Object} payload Buyer data from PayPal (like address, nonce, etc.)
 */
PayPalAccountModel.prototype.onApprovePaymentCallback = function (payload) {
	// Calling parrent method to proceed with PayPal payload validation
	PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload);

	// Error will be handled on BaseModel level
	if (this.error) {
		return;
	}

	this.loader.show();

	const payPalAccountModelHelper = require('../helpers/payPalAccountModel');
	const billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');

	const $payPalButton = document.querySelector(this.payPalButtonSelector);
	const paypalAccountFormFields = $payPalButton.getAttribute(
		'data-paypal-account-form-fields'
	);

	const payPalAccountModelInstance = this;
	const btDetails = payload.details;
	const payPalBraintreeAddressData =
		billingFormHelper.createBillingAddressData(btDetails);
	const paypalAccountFormData = helper.createPaymentFormData(
		paypalAccountFormFields,
		{
			nonce: payload.nonce,
			email: payload.details.email,
			addresses: JSON.stringify(payPalBraintreeAddressData),
			shippingAddress: JSON.stringify(payload.details.shippingAddress),
		}
	);
	// Apped CSRF token

	billingFormHelper.appendCsrfTokenToFormData(
		paypalAccountFormData,
		this.$csrfToken
	);

	payPalAccountModelHelper
		.savePayPalAccount(this.addPayPalAccountUrl, paypalAccountFormData)
		.then((response) => response.json())
		.then((data) => {
			if (data.error) {
				const error = data.error;

				payPalAccountModelInstance.alertHandlerModel.showError(error);
				payPalAccountModelInstance.loader.hide();
			} else {
				const renderAccountsUrl = data.renderAccountsUrl;

				payPalAccountModelHelper.hidePaypalAccountBtn();
				payPalAccountModelHelper
					.renderPayPalAccounts(renderAccountsUrl)
					.then((accountListTemplate) => accountListTemplate.text())
					.then((accountListTemplateHTML) => {
						payPalAccountModelHelper.addPayPalAccountsToTheTemplate(
							accountListTemplateHTML
						);
						payPalAccountModelHelper.throwCartUpdate();
						payPalAccountModelInstance.initRemovePayPalAccountEvent();
						payPalAccountModelInstance.initMakeDefaultPayPalAccountEvent();

						payPalAccountModelInstance.loader.hide();
					});
			}
		});
};

/**
 * Triggers when buyer clicked on the PayPal button and PayPal modal started loading
 * @returns {Object} Object which will be passed directly into Braintree PayPal SDK as an order data
 */
PayPalAccountModel.prototype.onOrderCreateCallback = async function () {
	const orderCreateParams = this.payPalConfigurations.options;

	// Hide custom error on buyer PayPal button click.
	this.alertHandlerModel.hideAlerts();

	return {
		flow: orderCreateParams.flow,
		enableShippingAddress: orderCreateParams.enableShippingAddress,
		displayName: orderCreateParams.displayName,
		billingAgreementDescription: orderCreateParams.billingAgreementDescription,
	};
};

/**
 * Init Remove PayPal Account button listner
 */
PayPalAccountModel.prototype.initRemovePayPalAccountEvent = function () {
	const $allRemovePayPalAccountButtons = document.querySelectorAll(
		'.js-paypal-accounts .js-remove-bt-payment'
	);

	$allRemovePayPalAccountButtons.forEach((btn) =>
		btn.addEventListener('click', helper.removeBtPayment)
	);
};

/**
 * Init Make Default PayPal account button
 */
PayPalAccountModel.prototype.initMakeDefaultPayPalAccountEvent = function () {
	const loaderInstance = require('../../loaderHelper');
	const $makeDefaultBtns = document.querySelectorAll(
		'.js-paypal-accounts .js-braintree-make-default-card'
	);

	$makeDefaultBtns.forEach((btn) =>
		btn.addEventListener('click', function () {
			const makeDefaultUrl = window.braintreeUrls.makePaymentMethodDefaultUrl;
			const paymentMehtodId = this.dataset.id;
			const paymentProcessorId =
				window.braintreePreferences.paymentMethods.BRAINTREE_PAYPAL
					.paymentMethodId;
			const loaderContainer = document.getElementById('paypalAccountBtLoader');
			const loader = loaderInstance(loaderContainer);

			loader.show();

			helper.setDefaultProperty({
				url: makeDefaultUrl,
				id: paymentMehtodId,
				paymentMethodID: paymentProcessorId,
				loader,
			});
		})
	);
};

module.exports = PayPalAccountModel;

'use strict';

/**
 * Inits SRC account functionality on the Account Page
 * @param {Promise} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
	const loaderInstance = require('../../loaderHelper');
	const srcHelper = require('../helpers/srcHelper');

	const SrcAlertHandlerModel = require('../alerthandler/srcAlertHandlerModel');
	const SrcAccountModel = require('../models/srcAccount');

	const $braintreeSrcLoader = document.querySelector(
		'.js-braintree-src-loader'
	);
	const $braintreeSrcButton = document.querySelector(
		'.js-braintree-src-account-button'
	);

	const srcButtonConfigurations = srcHelper.getBraintreeSRCButtonConfigs();
	const $braintreeSrcDeviceDataInput = null;
	const srcLoader = loaderInstance($braintreeSrcLoader);

	const alertHandler = new SrcAlertHandlerModel(
		srcButtonConfigurations.messages
	);
	const SrcAccountModelInstance = new SrcAccountModel(
		clientInstancePromise,
		srcButtonConfigurations,
		$braintreeSrcDeviceDataInput,
		srcLoader,
		alertHandler
	);

	// Hides errors on the Account Page
	$braintreeSrcButton.addEventListener('click', alertHandler.hideAlerts);

	SrcAccountModelInstance.initSrcButton();
}

module.exports = {
	init,
};

'use strict';

const helper = require('../../helper');
const venmoAccount = require('./venmoAccount');
const venmoCheckout = require('./venmoCheckout');
const venmoConstants = require('../constants/venmoConstants');
const venmoProcessingHelper = require('../helpers/venmoProcessingHelper');

/**
 * Process Venmo flow for Account/Billing Page flows
 * @param {Object} venmoData Venmo data received from tokenize
 */
function processVenmo(venmoData) {
	const $venmoButton = document.querySelector(
		'.js-braintree-account-venmo-button, .js-braintree-venmo-button'
	);

	if (
		$venmoButton &&
		helper.tryParseJSON($venmoButton.getAttribute('data-braintree-config'))
	) {
		const VenmoAlertHandlerModel = require('../alerthandler/venmoAlertHandlerModel');
		const alertHandler = new VenmoAlertHandlerModel();

		alertHandler.hideAlerts();
	}

	const venmoFlowName = venmoProcessingHelper.getVenmoFlowName();

	switch (venmoFlowName) {
		case venmoConstants.FLOW_NAME_ACCOUNT:
			venmoAccount.storeNewVenmoAccount(venmoData);

			break;

		case venmoConstants.FLOW_NAME_BILLING_CHECKOUT:
			helper.removeActiveSessionPayment();
			venmoCheckout.processCheckoutFlow(venmoData);

			break;

		default:
			break;
	}
}

module.exports = {
	processVenmo,
};

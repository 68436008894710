'use strict';

const helper = require('../../helper');

const $applePayButton = document.querySelector('.js-braintree-applepay-button');
const $applepayDataMethod = document.querySelector(
	'.payment-options[role=tablist] .nav-item[data-method-id="ApplePay"]'
);
const $phoneField = document.querySelector(
	'.contact-info-block [name=dwfrm_billing_contactInfoFields_phone]'
);

/**
 * Returns Braintree Apple Pay configs
 * @returns {Object} Braintree Apple Pay configs
 */
function getBraintreeApplePayConfigs() {
	let braintreeApplePayButtonConfigs = null;

	try {
		braintreeApplePayButtonConfigs = helper.tryParseJSON(
			document
				.querySelector('.js-braintree-applepay-button')
				.getAttribute('data-braintree-config')
		);
	} catch (error) {
		return braintreeApplePayButtonConfigs;
	}

	return braintreeApplePayButtonConfigs;
}

/**
 * Sets device data from 'data collector' to corresponding input field
 * @param {Object} fraudDataPayload Froud payload data
 */

function setApplePayDeviceData(fraudDataPayload) {
	const $braintreeApplePayDeviceData = document.getElementById(
		'braintreeApplePayDeviceData'
	);

	if (fraudDataPayload.deviceData) {
		$braintreeApplePayDeviceData.value = fraudDataPayload.deviceData;
	}
}

/**
 * Builds and shows Apple Pay button
 * @param {Object} buttonStyles An object with Apple pay button styles
 */
function renderApplePayButton(buttonStyles) {
	const $applePayBtn = document.getElementById('apple-pay-btn');

	// Builds an Apple pay button by parameters
	$applePayBtn.setAttribute('type', buttonStyles.type);
	$applePayBtn.setAttribute('buttonstyle', buttonStyles.buttonStyle);

	// Shows an Apple pay container
	$applePayButton.classList.add('d-block');

	// Shows an Apple pay tab on the billing page
	if ($applepayDataMethod) {
		$applepayDataMethod.classList.add('d-block');
	}
}

/**
 * Hides Apple Pay tab on the Checkout/Billing page
 */
function hideApplePayTab() {
	const $applePayButtonContainer = document.querySelector(
		'.js-applepay-container'
	);

	if ($applepayDataMethod) {
		$applepayDataMethod.classList.add('d-none');
	}

	if ($applePayButtonContainer) {
		$applePayButtonContainer.classList.add('d-none');
	}
}

/**
 * Marks Apple Pay button as disabled
 */
function makeApplePayButtonDisabled() {
	$applePayButton.classList.add('js-braintree-applepay-button_disabled');
}

/**
 * Remove session Apple Pay nonce
 */
function removeSessionNonce() {
	document.getElementById('braintreeApplePayNonce').value = '';
	document.getElementById('braintreeApplePayDeviceData').value = '';
}

/**
 * Validates required billing form fields
 * @returns {boolean} Whether billing form fields are valid
 */
function isBilligFiledsValid() {
	return helper.isValidInputField($phoneField);
}

/**
 * Depends of the 'data-paypal-is-hide-continue-button' hides or shows continue button on the Billing Page
 */
function toggleContinueButtonOnBillingPage() {
	const applePayContent = document.querySelector(
		'.js-braintree-applepay-content'
	);

	helper.continueButtonToggle(
		applePayContent.getAttribute('data-paypal-is-hide-continue-button')
	);
}

module.exports = {
	getBraintreeApplePayConfigs,
	setApplePayDeviceData,
	renderApplePayButton,
	makeApplePayButtonDisabled,
	removeSessionNonce,
	isBilligFiledsValid,
	hideApplePayTab,
	toggleContinueButtonOnBillingPage,
};

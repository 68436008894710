'use strict';

/* global paypal braintree $ */

let btPayPalCheckoutInstancePromise;

/**
 * Function creates PayPal Checkout instance
 * @param {Promise} btClientInstancePromise BT Client Instance Promise
 * @param {Object} payPalPaymentProcessingModal Instance of PayPalPaymentProcessing model. Is needed to have customize CheckoutInstance generation
 * depending on PayPalPaymentProcessing instance configs
 * @returns {Promise} Promise with PayPal Checkout Instance
 */
function createPayPalCheckoutInstance(
	btClientInstancePromise,
	payPalPaymentProcessingModal
) {
	return btClientInstancePromise.then(function (btClientInstance) {
		const payPalCheckoutConfigs =
			payPalPaymentProcessingModal.payPalCheckoutInstanceConfigs(
				btClientInstance
			);

		return braintree.paypalCheckout.create(payPalCheckoutConfigs);
	});
}

/**
 * Creates PayPal button style configs
 * @param {Object} btPayPalConfigurations Configurations of PayPal button
 * @returns {Object} Style configs with the right format
 */
function createPayPalStyleConfigurations(btPayPalConfigurations) {
	const payPalButtonStyleConfigs = btPayPalConfigurations.paypalConfig.style;

	return {
		height: payPalButtonStyleConfigs.height,
		color: payPalButtonStyleConfigs.color,
		shape: payPalButtonStyleConfigs.shape,
		layout: payPalButtonStyleConfigs.layout,
		label: payPalButtonStyleConfigs.label,
		tagline: payPalButtonStyleConfigs.tagline,
	};
}

/**
 * Callback which is came from PayPal configurations. Should trigger only "enable" action
 * @param {Object} validateActions Object from Braintree
 */
const payPalFormValidationControl = function (validateActions) {
	const isFormValid = true;

	if (isFormValid) {
		validateActions.enable();
	} else {
		validateActions.disable();
	}
};

const onApprove = function (data, checkoutInstance, paymentProcessingModal) {
	// Some logic here before tokenization happens below
	return checkoutInstance.tokenizePayment(data).then(function (payload) {
		paymentProcessingModal.onApprovePaymentCallback(payload);
	});
};

const createOrder = async function (checkoutInstance, paymentProcessingModal) {
	// Logic which executed after buyer clicked on the button, and PayPal modal start loading
	// "onOrderCreateCallback" is used for forming of order data object
	const paymentObject = await paymentProcessingModal.onOrderCreateCallback();

	return checkoutInstance.createPayment(paymentObject);
};

/**
 * Returns PayPal button behavior configs which are used when VaultMode is enabled
 * @param {Promise} btPayPalCheckoutInstance Promise with PayPal Checkout Instance
 * @param {Object} btPayPalConfigurations Object with PayPal configurations
 * @param {Object} payPalPaymentProcessingModal Instance of PayPalPaymentProcessing model
 * @returns {Object} Object with PayPal button configs & callbacks which will handle PayPal button behavior
 */
function getVaultModeButtonConfigurations(
	btPayPalCheckoutInstance,
	btPayPalConfigurations,
	payPalPaymentProcessingModal
) {
	let vaultModePayPalButtonConfigs = {};
	const payPalButtonStyleConfigs = createPayPalStyleConfigurations(
		btPayPalConfigurations
	);

	vaultModePayPalButtonConfigs = {
		onClick: function (_, actions) {
			return payPalPaymentProcessingModal.onPayPalButtonClickCallback(
				_,
				actions
			);
		},
		createBillingAgreement: function () {
			return createOrder(
				btPayPalCheckoutInstance,
				payPalPaymentProcessingModal
			);
		},
		onApprove: function (data) {
			return onApprove(
				data,
				btPayPalCheckoutInstance,
				payPalPaymentProcessingModal
			);
		},
		onCancel: function () {
			payPalPaymentProcessingModal.onCancelPaymentCallback();
		},

		onError: function (err) {
			payPalPaymentProcessingModal.onErrorPaymentCallback(err);
		},
		style: payPalButtonStyleConfigs,
		validate: function (validateActions) {
			payPalFormValidationControl(validateActions);
		},
	};

	return vaultModePayPalButtonConfigs;
}

/**
 * Returns PayPal button behavior configs which are used when VaultMode is disabled or "Checkout with Vault" is enabled
 * @param {Promise} btPayPalCheckoutInstance Promise with PayPal Checkout Instance
 * @param {Object} btPayPalConfigurations Object with PayPal configurations
 * @param {Object} payPalPaymentProcessingModal Instance of PayPalPaymentProcessing model
 * @returns {Object} Object with PayPal button configs & callbacks which will handle PayPal button behavior
 */
function getCheckoutModeButtonConfigurations(
	btPayPalCheckoutInstance,
	btPayPalConfigurations,
	payPalPaymentProcessingModal
) {
	let vaultModePayPalButtonConfigs = {};
	const payPalButtonStyleConfigs = createPayPalStyleConfigurations(
		btPayPalConfigurations
	);

	vaultModePayPalButtonConfigs = {
		onClick: function (_, actions) {
			return payPalPaymentProcessingModal.onPayPalButtonClickCallback(
				_,
				actions
			);
		},
		createOrder: function () {
			return createOrder(
				btPayPalCheckoutInstance,
				payPalPaymentProcessingModal
			);
		},
		onApprove: function (data) {
			return onApprove(
				data,
				btPayPalCheckoutInstance,
				payPalPaymentProcessingModal
			);
		},
		onCancel: function () {
			payPalPaymentProcessingModal.onCancelPaymentCallback();
		},

		onError: function (err) {
			payPalPaymentProcessingModal.onErrorPaymentCallback(err);
		},
		style: payPalButtonStyleConfigs,
		validate: function (validateActions) {
			payPalFormValidationControl(validateActions);
		},
	};

	return vaultModePayPalButtonConfigs;
}

/**
 * Hide PayPal banner block if eligible
 * @returns {void}
 */
function hidePayPalBannerIfEligible() {
	const payPalCreditBtn = paypal.Buttons({
		fundingSource: paypal.FUNDING.CREDIT,
	});
	const isPayPalCreditEligible = payPalCreditBtn.isEligible();
	const $payPalBanner = document.querySelector('.js-pp-banner');

	if (isPayPalCreditEligible && $payPalBanner) {
		$payPalBanner.classList.add('d-none');
	}
}

/**
 * Function which result is rendered PayPal button
 * @param {Promise} btClientInstancePromise Client instance promise
 * @param {boolean} isVaultMode "true" in case if VaultMode is enabled
 * @param {Object} btPayPalConfigurations Object with PayPal configurations
 * @param {Array} payPalPaymentProcessingModels array of instances for PayPalPaymentProcessing model
 * @returns {Promise} PayPal button
 */
function payPalButtonRendering(
	btClientInstancePromise,
	isVaultMode,
	btPayPalConfigurations,
	payPalPaymentProcessingModels
) {
	// Get ppModel to identify the flow and create sdk config
	const ppPaymentProcessingModel = payPalPaymentProcessingModels.find(Boolean);

	if (!btPayPalCheckoutInstancePromise) {
		// In case if paypal SDK is loaded only for credit messages, we need to delete it
		// and load again to avoid getting unhandled errors in console
		if (window.isPayPalCreditMessageLoaded) {
			delete window.paypal;
		}

		btPayPalCheckoutInstancePromise = createPayPalCheckoutInstance(
			btClientInstancePromise,
			ppPaymentProcessingModel
		);
	}

	function getPayPalButtonConfig(
		changePMButtonEnabled,
		vaultMode,
		btPayPalCheckoutInstance,
		btPayPalConfig,
		payPalPaymentProcessingModel
	) {
		if (changePMButtonEnabled) {
			return getCheckoutModeButtonConfigurations(
				btPayPalCheckoutInstance,
				btPayPalConfig,
				payPalPaymentProcessingModel
			);
		}

		if (vaultMode) {
			return getVaultModeButtonConfigurations(
				btPayPalCheckoutInstance,
				btPayPalConfig,
				payPalPaymentProcessingModel
			);
		}

		return getCheckoutModeButtonConfigurations(
			btPayPalCheckoutInstance,
			btPayPalConfig,
			payPalPaymentProcessingModel
		);
	}

	return btPayPalCheckoutInstancePromise.then(function (
		btPayPalCheckoutInstance
	) {
		let paypalButtonSdkUrlConfigs = null;

		const changePMButtonEnabled =
			ppPaymentProcessingModel.changePMButtonEnabled;

		if (changePMButtonEnabled) {
			paypalButtonSdkUrlConfigs =
				ppPaymentProcessingModel.payPalCheckoutSdkConfigs();
		} else if (isVaultMode) {
			paypalButtonSdkUrlConfigs =
				ppPaymentProcessingModel.payPalVaultSdkConfigs();
			// If checkout mode (single use token)
		} else {
			paypalButtonSdkUrlConfigs =
				ppPaymentProcessingModel.payPalCheckoutSdkConfigs();
		}

		// PayPal button loading
		if (!window.isPayPalSDKLoaded) {
			// We pass params to customize PayPal SDK loading.
			btPayPalCheckoutInstance
				.loadPayPalSDK(paypalButtonSdkUrlConfigs)
				.then(function () {
					window.isPayPalSDKLoaded = true;

					// Loop through instances and render PP button
					payPalPaymentProcessingModels.forEach(
						(payPalPaymentProcessingModel) => {
							const paypalButtonConfigs = getPayPalButtonConfig(
								changePMButtonEnabled,
								isVaultMode,
								btPayPalCheckoutInstance,
								btPayPalConfigurations,
								payPalPaymentProcessingModel
							);

							// eslint-disable-next-line new-cap
							paypal
								.Buttons(paypalButtonConfigs)
								.render(payPalPaymentProcessingModel.payPalButtonSelector);

							hidePayPalBannerIfEligible();
						}
					);
				});
		} else {
			// In case if PayPal SDK was already loaded (with custom parameters), we can simply load PP buttons
			payPalPaymentProcessingModels.forEach((payPalPaymentProcessingModel) => {
				const paypalButtonConfigs = getPayPalButtonConfig(
					changePMButtonEnabled,
					isVaultMode,
					btPayPalCheckoutInstance,
					btPayPalConfigurations,
					payPalPaymentProcessingModel
				);

				// eslint-disable-next-line new-cap
				paypal
					.Buttons(paypalButtonConfigs)
					.render(payPalPaymentProcessingModel.payPalButtonSelector);
			});
		}
	});
}

module.exports = {
	payPalButtonRendering,
};

'use strict';

const helper = require('../../helper');

/**
 * General validation of params needed for initialization of Venmo button
 * @param {Object} braintreeVenmoConfig Braintree Venmo button configurations (they came from server via data attr)
 * @returns {Object} object with "error" boolean flag and array of error messages from "messages" attr
 */
function generalVenmoValidation(braintreeVenmoConfig) {
	const response = {
		error: false,
		messages: '',
	};

	try {
		const isBraintreeVenmoConfigValid =
			typeof braintreeVenmoConfig !== 'object' || braintreeVenmoConfig !== null;

		if (!isBraintreeVenmoConfigValid) {
			response.error = true;
			response.messages = 'Venmo button has not valid data-braintree-config';
		}
	} catch (error) {
		response.error = true;
		response.messages = error;
	}

	return response;
}

/**
 * Init Venmo button functionality
 * @param {Object} btVenmoModel Braintree Venmo model
 * @param {Object} $venmoButton Venmo button element
 * @param {boolean} isBillingPage value to identify flow page
 */
function init(btVenmoModel, $venmoButton, isBillingPage) {
	const braintreeVenmoConfig = helper.tryParseJSON(
		$venmoButton.getAttribute('data-braintree-config')
	);
	const VenmoAlertHandlerModel = require('../alerthandler/venmoAlertHandlerModel');
	const alertHandler = new VenmoAlertHandlerModel();

	try {
		const generalValidation = generalVenmoValidation(braintreeVenmoConfig);

		if (generalValidation.error && generalValidation.messages.length) {
			throw generalValidation.messages;
		}

		// Fraud Data collecting
		// For more details go inside "btVenmoModel.collectFraudData" function logic
		btVenmoModel.collectFraudData().then(function (fraudDataPayload) {
			const venmoHelper = require('../helpers/venmoHelper');

			venmoHelper.setVenmoDeviceData(fraudDataPayload.deviceData);

			return btVenmoModel
				.isBrowserSupported()
				.then(function (isBrowserSupported) {
					if (!isBrowserSupported) {
						alertHandler.handleNoBrowserSupportError(braintreeVenmoConfig);
					}

					return btVenmoModel.initVenmo($venmoButton, isBillingPage);
				});
		});

		// To prevent repeatable Venmo button initialization
		window.venmoCheckoutButtonInited = true;
	} catch (error) {
		alertHandler.showError(error);
	}
}

module.exports = {
	init,
};

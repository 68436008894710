'use strict';

const AlertHandlerModel = require('../../alertHandler');
const googlePayErrorMessages = require('../constants/errorMessages');

/**
 * Google Pay alert handler model
 */
class GooglePayAlertHandlerModel extends AlertHandlerModel {
	constructor(alertMessages) {
		super();
		this.alertMessages = alertMessages;
	}

	/**
	 * Shows order total equal 0 error
	 */
	showOrderTotalError() {
		const zeroAmountErrorMsg = googlePayErrorMessages.ORDER_TOTAL_0;

		this.showError(zeroAmountErrorMsg);
		throw zeroAmountErrorMsg;
	}

	/**
	 * Handles Google Pay error by types
	 * @param {Object} error Error object or error message
	 */
	handleGooglePayError(error) {
		if (error.name === 'BraintreeError') {
			this.showBraintreeError(error);
		} else if (error.statusCode === 'DEVELOPER_ERROR') {
			this.showError(error.statusMessage);
		} else if (
			error.message !== googlePayErrorMessages.GOOGLE_PAY_WINDOW_CLOSED
		) {
			this.showError(error.message);
		}
	}

	/**
	 * Creates a Google Pay error object to show on Google Pay popup
	 * @param {string} intent Intent of Google Pay error
	 * @param {string} errorMessage Error message
	 * @returns {Object} An error pbject
	 */
	getPaymentDataError(intent, errorMessage) {
		const googlePayConstants = require('../constants/googlePayPaymentProcessingConstants');
		const errorMessages = require('../constants/errorMessages');

		if (intent === googlePayConstants.INTENT_SHIPPING_OPTION) {
			return {
				reason: googlePayConstants.REASON_SHIPPING_OPTION_INVALID,
				message: errorMessage || errorMessages.SHIPPING_OPTION_INVALID,
				intent: intent,
			};
		}

		if (intent === googlePayConstants.INTENT_SHIPPING_ADDRESS) {
			return {
				reason: googlePayConstants.REASON_SHIPPING_ADDRESS_UNSERVICEABLE,
				message: errorMessages.SHIPPING_ADDRESS_UNSERVICEABLE,
				intent: intent,
			};
		}

		return {};
	}
}

module.exports = GooglePayAlertHandlerModel;

'use strict';

const braintreeGooglePayModelBuilder = require('./braintreesdk/braintreeGooglePayModelBuilder');

const googlePayHelper = require('./helpers/googlePayHelper');

// Components
const googlePayList = require('./components/googlePayList');
const googlePayPaymentProcessing = require('./components/googlePayPaymentProcessing');

const GooglePayAlertHandlerModel = require('./alerthandler/googlePayAlertHandlerModel');

const googlePayErrorMessages = require('./constants/errorMessages');

let braintreeGooglePayModelResponse;
let googlePayMessages;

/**
 * Inits Google Pay on the Cart Page
 */
function initCart() {
	const alertHandler = new GooglePayAlertHandlerModel(googlePayMessages);

	if (
		!braintreeGooglePayModelResponse ||
		braintreeGooglePayModelResponse.error
	) {
		const error =
			braintreeGooglePayModelResponse.error ||
			googlePayErrorMessages.NO_GOOGLE_PAY_BRAINTREE_CONFIGURATIONS;

		alertHandler.handleGooglePayError(error);
	}

	// Initialize a Google Pay 'PaymentsClient' object
	braintreeGooglePayModelResponse.btGooglePayModel.initPaymentsClient(
		braintreeGooglePayModelResponse.btGooglePayConfigs.instanceType,
		window.braintreeConstants.PAGE_FLOW_CART
	);

	googlePayPaymentProcessing.init(
		braintreeGooglePayModelResponse.btGooglePayModel,
		alertHandler
	);
}

/**
 * Inits Google Pay on the Checkout Page
 */
function initCheckout() {
	const alertHandler = new GooglePayAlertHandlerModel(googlePayMessages);

	if (
		!braintreeGooglePayModelResponse ||
		braintreeGooglePayModelResponse.error
	) {
		const error =
			braintreeGooglePayModelResponse.error ||
			googlePayErrorMessages.NO_GOOGLE_PAY_BRAINTREE_CONFIGURATIONS;

		alertHandler.handleGooglePayError(error);
	}

	// Triggers the Google Pay tab if Google Pay session account uses
	googlePayHelper.triggerGooglePayTab(
		braintreeGooglePayModelResponse.btGooglePayConfigs
	);

	// Initialize a Google Pay 'PaymentsClient' object
	braintreeGooglePayModelResponse.btGooglePayModel.initPaymentsClient(
		braintreeGooglePayModelResponse.btGooglePayConfigs.instanceType
	);

	googlePayPaymentProcessing.init(
		braintreeGooglePayModelResponse.btGooglePayModel,
		alertHandler
	);

	googlePayList.init();
}

/**
 * Inits Google Pay components
 */
function init() {
	braintreeGooglePayModelResponse = braintreeGooglePayModelBuilder.init();

	if (!braintreeGooglePayModelResponse.error) {
		googlePayMessages =
			braintreeGooglePayModelResponse.btGooglePayConfigs.messages;
	}
}

module.exports = {
	init,
	initCart,
	initCheckout,
};

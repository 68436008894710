'use strict';

/* eslint-disable no-undef */
/* global braintreeUtils braintree $ */

/**
 * Creates visa checkout instance
 * @param {Promise} btClientInstancePromise BT Client Instance Promise
 * @returns {Promise} visaCheckoutInstance SRC instance
 */
function createVisaCheckout(btClientInstancePromise) {
	return btClientInstancePromise.then(function (btClientInstance) {
		return braintree.visaCheckout.create({
			client: btClientInstance,
		});
	});
}

/**
 * Initialized SRC with options and pass payload data after tokenize
 * @param {Object} SrcBaseModelInstance Instance of SrcBaseModel or it's child classes
 * @returns {Promise} SRC button
 */
function visaCheckoutInitialized(SrcBaseModelInstance) {
	const visaCheckoutInstancePromise = createVisaCheckout(
		SrcBaseModelInstance.clientInstancePromise
	);

	return visaCheckoutInstancePromise.then(function (visaCheckoutInstance) {
		const srcInitOptions = visaCheckoutInstance.createInitOptions(
			SrcBaseModelInstance.getInitOptionsObject()
		);

		V.init(srcInitOptions);

		// Event which is triggered when payment successfully completed on SRC end
		V.on('payment.success', function (payment) {
			visaCheckoutInstance.tokenize(payment).then(function (payload) {
				SrcBaseModelInstance.onTokenizeCallback(payload);
			});
		});
		V.on('payment.error', function (_, error) {
			SrcBaseModelInstance.alertHandler.handleSrcError(error);
		});
		V.on('payment.cancel', function (payment) {
			if (payment.error) {
				SrcBaseModelInstance.alertHandler.showError(
					SrcBaseModelInstance.alertHandler.alertMessages.CLIENT_GATEWAY_NETWORK
				);
			}
		});
	});
}

module.exports = {
	visaCheckoutInitialized,
};

'use strict';

const PayPalCartModel = require('../models/payPalCartModel');
const PaypalAlertHandlerModel = require('../alerthandler/paypalAlertHandlerModel');
const helper = require('../../helper');

/**
 * Initialize Cart page PayPal button
 * @param {Promise} clientInstancePromise Client instance promise
 */
function init(clientInstancePromise) {
	const payPalButtonSelector = '.cart-page .js-braintree-paypal-cart-button';
	const $payPalButton = document.querySelector(payPalButtonSelector);
	const payPalConfigurations = helper.tryParseJSON(
		$payPalButton.getAttribute('data-braintree-config')
	);

	const $csrfToken = document.querySelector(
		'.js-braintree-cart-paypal-buttons-wrap #csrf_token'
	);
	const $loaderContainer = document.querySelector(
		'.js-braintree-paypal-loader'
	);

	const alertHandler = new PaypalAlertHandlerModel(
		payPalConfigurations.messages
	);
	const PayPalExpress = new PayPalCartModel(
		alertHandler,
		clientInstancePromise,
		payPalButtonSelector,
		payPalConfigurations,
		$csrfToken,
		$loaderContainer
	);

	// Generate Fraud Data if enabled
	PayPalExpress.generateFraudData();
	// Init PayPal button
	PayPalExpress.initPayment();
}

module.exports = {
	init,
};

'use strict';

/**
 * Creates tokenization options for 'tokenize' function.
 * @returns {Object} Object with tokenization options.
 */
function createTokenizationOptions() {
	const creditCardBillingAddressHelper = require('./creditCardBillingAddressHelper');

	return {
		billingAddress:
			creditCardBillingAddressHelper.createCreditCardBillingAddress(),
	};
}

module.exports = {
	createTokenizationOptions,
};

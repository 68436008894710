'use strict';

const PayPalProductPageModel = require('../models/payPalProductModel');
const PaypalAlertHandlerModel = require('../alerthandler/paypalAlertHandlerModel');

const payPalButtonDisplayBehavior = require('./payPalButtonDisplayBehavior');
const braintreePayPalSDK = require('../braintreesdk/braintreePayPalSDK');
const helper = require('../../helper');

/**
 * Init PayPal button on the Product Page
 * @param {Promise} clientInstancePromise Client Instance Promise
 */
function init(clientInstancePromise) {
	const pvpModels = [];
	const PP_BTN_SELECTOR = '.js-braintree-paypal-cart-button';
	const paypalButtonContainers = document.querySelectorAll(
		'.js-braintree-pdp-button, .js-braintree-pdp-button-global'
	);

	let isVaultMode;
	let payPalConfigurations;

	if (paypalButtonContainers) {
		paypalButtonContainers.forEach((container, index) => {
			const $payPalButton = container.querySelector(PP_BTN_SELECTOR);
			const $loaderContainer = container.querySelector(
				'.js-braintree-paypal-loader'
			);
			const $csrfToken = container.querySelector(
				'.js-braintree-cart-paypal-buttons-wrap #csrf_token'
			);

			if ($payPalButton) {
				$payPalButton.classList.add(`${PP_BTN_SELECTOR.slice(1)}-${index}-pdp`);

				const selector = `.${Array.from($payPalButton.classList).join('.')}`;

				payPalConfigurations = helper.tryParseJSON(
					$payPalButton.getAttribute('data-braintree-config')
				);
				isVaultMode = payPalConfigurations.options.flow !== 'checkout';

				const alertHandler = new PaypalAlertHandlerModel(
					payPalConfigurations.messages
				);

				const PayPalProductPage = new PayPalProductPageModel(
					alertHandler,
					clientInstancePromise,
					selector,
					payPalConfigurations,
					$csrfToken,
					$loaderContainer
				);

				// Generate Fraud Data if enabled
				PayPalProductPage.generateFraudData();

				pvpModels.push(PayPalProductPage);
			}
		});

		// Init PayPal buttons
		braintreePayPalSDK.payPalButtonRendering(
			clientInstancePromise,
			isVaultMode,
			payPalConfigurations,
			pvpModels
		);

		// Init behavior for hide/show PayPal button
		payPalButtonDisplayBehavior.initProductPage();
	}
}

module.exports = {
	init,
};

'use strict';

const AlertHandlerModel = require('../../alertHandler');

/**
 * SRC error handler model
 */
class SrcAlertHandlerModel extends AlertHandlerModel {
	constructor(alertMessages) {
		super();
		this.alertMessages = alertMessages;
	}

	/**
	 * Shows 'Zero amount' error
	 */
	showZeroAmountError() {
		this.showError(this.alertMessages.CUSTOM_SRC_ZERO_AMOUNT_ERROR);
	}

	/**
	 * Handles SRC error by types
	 * @param {Object} error Error object or error message
	 */
	handleSrcError(error) {
		if (error.name === 'BraintreeError') {
			this.showBraintreeError(error);
		} else {
			this.showError(error.message || error);
		}
	}

	/**
	 * Handles error on checkout from cart
	 * @param {Object} data error data
	 */
	handleCheckoutFromCartError(data) {
		let errorMessage = '';

		if (data.fieldErrors.length) {
			data.fieldErrors.forEach((error, index) => {
				const keys = Object.keys(error);

				if (keys.length) {
					errorMessage += `${keys[index]
						.replace('dwfrm_billing_', '')
						.replace('_', ' ')} ${data.fieldErrors[index][keys[index]]}. `;
				}
			});

			this.showError(errorMessage);
		}

		if (data.serverErrors.length) {
			data.serverErrors.forEach((error) => {
				errorMessage += `${error}. `;
			});

			this.showError(errorMessage);
		}

		if (data.cartError) {
			window.location.href = data.redirectUrl;
		}
	}
}

module.exports = SrcAlertHandlerModel;

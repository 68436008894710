'use strict';

const AlertHandlerModel = require('../../alertHandler');

/**
 * Venmo error handler model
 */
class VenmoAlertHandlerModel extends AlertHandlerModel {
	constructor() {
		super();
	}

	/**
	 * Handles error flows in case when browser is not supported for Billing/Account Pages
	 * @param {Object} braintreeVenmoConfig Braintree Venmo config
	 */
	handleNoBrowserSupportError(braintreeVenmoConfig) {
		const $venmoAccountWrapper = document.querySelector(
			'.js-braintree-account-venmo-button-wrapper'
		);

		if ($venmoAccountWrapper) {
			this.showError(braintreeVenmoConfig.messages.VENMO_BROWSER_NOT_SUPPORTED);
		} else {
			document
				.querySelector('.js-braintree-venmo-button')
				.parentElement.firstElementChild.classList.add('d-none');
			document
				.querySelector(
					'.payment-options[role=tablist] .nav-item[data-method-id="Venmo"]'
				)
				.classList.add('d-none');
		}
	}
}

module.exports = VenmoAlertHandlerModel;

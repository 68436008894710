'use strict';

const helper = require('../../helper');

/**
 * Init add new PayPal account button
 * @param {Object} PayPalAccount PayPal Account model instance
 */
function initAddNewPayPalAccountEvent(PayPalAccount) {
	const payPalAccountPageHelper = require('../helpers/payPalAccountPage');
	const $addNewPayPalAccountButton = document.querySelector(
		'.js-add-paypal-account'
	);

	$addNewPayPalAccountButton.addEventListener('click', function () {
		payPalAccountPageHelper.showPayPalAccountBtn();

		if (!window.accountPayPalButtonInited) {
			window.accountPayPalButtonInited = true;
			PayPalAccount.initPayment();
		}
	});
}

/**
 * Function which initiate PayPal functionality on the Account Page
 * @param {Object} btClientInstancePromise BT Client Instance promise
 */
function init(btClientInstancePromise) {
	const payPalButtonSelector =
		'.js-braintree-account-paypal-button-wrapper .js-braintree-account-paypal-button';
	const $payPalButton = document.querySelector(payPalButtonSelector);

	if ($payPalButton) {
		const PaypalAlertHandlerModel = require('../alerthandler/paypalAlertHandlerModel');
		const PayPalAccountModel = require('../models/payPalAccount');

		const payPalConfigurations = helper.tryParseJSON(
			$payPalButton.getAttribute('data-braintree-config')
		);
		const addPayPalAccountUrl = window.braintreeUrls.paypalAddAccountHandlerUrl;

		const $csrfToken = document.querySelector(
			'.js-braintree-account-paypal-button-wrapper #csrf_token'
		);
		const $loaderContainer = document.getElementById(
			'braintreePayPalAccLoader'
		);

		// Init params for AccountErrorHandling model
		const errorMessages = payPalConfigurations.messages;

		const alertHandler = new PaypalAlertHandlerModel(errorMessages);
		const PayPalAccount = new PayPalAccountModel(
			alertHandler,
			btClientInstancePromise,
			payPalButtonSelector,
			payPalConfigurations,
			$csrfToken,
			addPayPalAccountUrl,
			$loaderContainer
		);

		initAddNewPayPalAccountEvent(PayPalAccount);
		PayPalAccount.initRemovePayPalAccountEvent();
		PayPalAccount.initMakeDefaultPayPalAccountEvent();
	} else {
		const $allRemovePayPalAccountButtons = document.querySelectorAll(
			'.js-paypal-accounts .js-remove-bt-payment'
		);

		$allRemovePayPalAccountButtons.forEach((btn) =>
			btn.addEventListener('click', helper.removeBtPayment)
		);
	}
}

module.exports = {
	init,
};

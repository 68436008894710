'use strict';

const braintreeVenmoSDK = require('./braintreeVenmoSDK');
const braintreeGeneral = require('../../braintreeGeneral');
const venmoProcessing = require('../components/venmoProcessing');
const venmoHelper = require('../helpers/venmoHelper');

/* global Promise braintree $ */

// global variables
let btClientInstancePromise;

/**
 * Init Braintree Venmo model
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 */
function init(braintreeClientInstancePromise) {
	braintreeVenmoSDK.init(braintreeClientInstancePromise);

	btClientInstancePromise = braintreeClientInstancePromise;
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
	let response;

	try {
		response = braintreeGeneral.collectFraudData(btClientInstancePromise);
	} catch (error) {
		response = Promise.resolve({
			customMessage:
				'Fraud Data collection isn`t enabled via Custom Site Preference',
		});
	}

	return response;
}

/**
 * Checks whether browser supports Venmo
 * @returns {Promise} with isBrowserSupported value
 */
function isBrowserSupported() {
	return braintreeVenmoSDK.isBrowserSupported();
}

/**
 * Inits Venmo button
 * @param {Element} $venmoButton Venmo button element
 * @param {Booleean} isBillingPage "true/false" value to identify flow page
 * @returns {Promise} Promise resolved with a tokenized payload
 */
function initVenmo($venmoButton, isBillingPage) {
	return braintreeVenmoSDK.createVenmo().then(function (venmoInstance) {
		$venmoButton.addEventListener('click', function () {
			// Check billing form to close Venmo pop-up and prevent tokenize action if any error (empty email input)
			// Check if amount is not zero to close Venmo pop-up and show appropriate error
			if (
				isBillingPage &&
				(!venmoHelper.checkBillingForm() || venmoHelper.checkZeroAmount())
			) {
				return undefined;
			}

			return braintreeVenmoSDK
				.tokenize(venmoInstance)
				.then(function (payload) {
					return venmoProcessing.processVenmo(payload);
				})
				.catch(function (error) {
					const VenmoAlertHandlerModel = require('../alerthandler/venmoAlertHandlerModel');
					const alertHandler = new VenmoAlertHandlerModel();

					alertHandler.showError(error.message);
				});
		});
	});
}

module.exports = {
	init,
	collectFraudData,
	isBrowserSupported,
	initVenmo,
};

'use strict';

const lpmHelper = require('../helpers/lpmHelper');
const lpmErrorMessages = require('../constants/errorMessages');
const AlertHandlerModel = require('../../alertHandler');

/**
 * LPM alert handler model
 */
class LpmAlertHandlerModel extends AlertHandlerModel {
	constructor() {
		super();
		const lpmConfigs = lpmHelper.getBraintreeLpmConfigs();

		if (lpmConfigs) {
			this.alertMessages = lpmConfigs.messages;
		}
	}

	/**
	 * Process error form Braintree or Braintree-PaymentConfirm endpoint
	 * @param {Object} error Error object
	 */
	handleLpmError(error) {
		if (error.name === 'BraintreeError') {
			this.showBraintreeError(error);
		} else {
			this.showError(error.responseText);
		}
	}

	/**
	 * Creates and throws an error if order amount is 0
	 * @param {string} paymentMethodName Payment method name
	 */
	handleZeroAmountOnCheckoutError(paymentMethodName) {
		const zeroAmountErrorMsg = lpmErrorMessages.ORDER_TOTAL_0.concat(
			'',
			paymentMethodName
		);

		this.showError(zeroAmountErrorMsg);
		throw zeroAmountErrorMsg;
	}
}

module.exports = LpmAlertHandlerModel;

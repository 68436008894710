'use strict';

const SrcBaseModel = require('./srcBaseModel');

// Helpers
const srcHelper = require('../helpers/srcHelper');
const helper = require('../../helper');

// See description of "srcBaseModel" for more info
function SrcCheckoutModel(
	clientInstancePromise,
	srcButtonConfigurations,
	$braintreeSrcDeviceDataInput,
	loader,
	alertHandler
) {
	SrcBaseModel.call(
		this,
		clientInstancePromise,
		srcButtonConfigurations,
		$braintreeSrcDeviceDataInput,
		loader,
		alertHandler
	);
}

SrcCheckoutModel.prototype = Object.create(SrcBaseModel.prototype);

// See description of "srcBaseModel" for more info
// Add nonce and billing data to the from and trigger click on "SubmitPayment" button
SrcCheckoutModel.prototype.onTokenizeCallback = function (srcPayload) {
	SrcBaseModel.prototype.onTokenizeCallback.call(this, srcPayload);

	this.loader.show();

	const that = this;

	const $saveCardCheckbox = document.getElementById('braintreeSaveSRCAccount');
	let checkForDuplicatedCCPromise;

	if ($saveCardCheckbox && $saveCardCheckbox.checked) {
		checkForDuplicatedCCPromise = helper.checkForDuplicatedCC(srcPayload);
	} else {
		checkForDuplicatedCCPromise = Promise.resolve(); // eslint-disable-line no-undef
	}

	checkForDuplicatedCCPromise
		.then((response) => {
			if (response && response.error) {
				return Promise.reject(response); // eslint-disable-line no-undef
			}

			return Promise.resolve(); // eslint-disable-line no-undef
		})
		.then(() => {
			helper.removeActiveSessionPayment();

			const isSessionPaymentsEnabled =
				window.braintreePreferences.isSessionPaymentsEnabled;
			const $srcNonce = document.getElementById('braintreeSRCNonce');
			const $srcBillingAddress = document.getElementById(
				'braintreeSrcBillingAddress'
			);
			const $continueButton = document.querySelector('button.submit-payment');
			const srcBillingData = srcPayload.billingAddress;

			srcPayload.billingAddressString = srcHelper.createSrcBillingAddress(
				srcPayload.billingAddress,
				srcPayload.userData
			);

			$srcNonce.value = srcPayload.nonce;
			srcBillingData.email = srcPayload.userData.userEmail;
			$srcBillingAddress.value = JSON.stringify(srcBillingData);

			if (isSessionPaymentsEnabled) {
				const srcSessionAccountComponent = require('../components/srcSessionAccount');

				srcSessionAccountComponent.showSrcSessionAccount(srcPayload);
			}

			srcHelper.updateStorefrontBillingData(srcBillingData);

			$continueButton.click();
		})
		.catch((error) => {
			that.alertHandler.handleSrcError(error);
		})
		.finally(() => {
			that.loader.hide();
		});
};

module.exports = SrcCheckoutModel;

/* eslint-disable no-console */

'use strict';

/* global Promise braintree $ */

const helper = require('../../helper');
const btCreditCardModel = require('../braintreesdk/braintreeCreditCardModel');
const creditCardPaymentProcessingHelper = require('../helpers/creditCardPaymentProcessingHelper');
const loaderInstance = require('../../loaderHelper');
const creditCardPaymentProcessingConstants = require('../constants/creditCardPaymentProcessingConstants');
const creditCardHelper = require('../helpers/creditCardHelper');

const creditCardBillingAddressHelper = require('../helpers/creditCardBillingAddressHelper');

const $continueButton = document.querySelector('button.submit-payment');
const $continueButtonNew = document.querySelector('button.submit-payment-new');
const $creditCardList = document.getElementById('braintreeCreditCardList');
const $braintreeCreditCardLoader = document.getElementById(
	'braintreeCreditCardLoader'
);
const $customCreditCardErrorContainer = document.getElementById(
	'customCreditCardErrorContainer'
);

let loader;
let alertHandler;

let hfInstance;

/**
 * Checking for customError property in payload
 * @param {Object} payload object with btPayload
 * @throws {Object} when customError property exists in payload
 */
function checkForErrorInPayload(payload) {
	if (Object.hasOwnProperty.call(payload, 'customError')) {
		throw payload;
	}
}

/**
 * Process a stored card
 * @param {clientInstancePromise} clientInstancePromise A ClientInstancePromise
 * @param {string} email An email address
 * @param {string} billingData A billing address data
 * @returns {Promise} A promise
 */
const processStoredCard = (clientInstancePromise, email, billingData) => {
	const savedCcBillingAddressAsString =
		creditCardHelper.getSavedCcBillingAddressAsString();

	// Fills a credit card billing address input with billing address data
	if (savedCcBillingAddressAsString) {
		// Case when a Credit card has a saved billing address
		// Using only for updating a billing address on the server side
		creditCardBillingAddressHelper.setCreditCardBillingAddress(
			helper.tryParseJSON(savedCcBillingAddressAsString)
		);
	} else {
		// Case when a Credit Card does not have a saved billing address
		creditCardBillingAddressHelper.setCreditCardBillingAddress(
			creditCardBillingAddressHelper.createCreditCardBillingAddress()
		);
	}

	const $selectedCcOption = creditCardHelper.getSelectedCcAccountOption();
	const selectedCcOptionId = $selectedCcOption.value;

	if (hfInstance.isCcReVerifyRequired(selectedCcOptionId)) {
		hfInstance
			.tokenizeCvv()
			.then((tokenizePaylaod) => {
				hfInstance.initPaymentDetailsSelectorObserver(selectedCcOptionId);
				document.getElementById('braintreeCvvOnlyNonce').value =
					tokenizePaylaod.btTokenizePayload.nonce;
			})
			.catch(() => {
				document.getElementById('braintreeCvvOnlyNonce').value = 'error';
			});
	}

	return creditCardPaymentProcessingHelper
		.getNonceFromStoredCard(clientInstancePromise, $creditCardList)
		.then(function (response) {
			if (!response) {
				throw Error('Payment method was not found in vault manager.');
			}

			return btCreditCardModel.getAuthInsightRegulationEnvironment(
				response.nonce
			);
		})
		.then(function (response) {
			return creditCardPaymentProcessingHelper
				.getNonceFromStoredCard(clientInstancePromise, $creditCardList)
				.then(function (paymentMethodResponse) {
					paymentMethodResponse.authenticationInsight = {
						regulationEnvironment: response.regulationEnvironment,
					};

					return btCreditCardModel
						.processStoredCard(email, billingData, paymentMethodResponse)
						.then(function (btStoredCardPayload) {
							checkForErrorInPayload(btStoredCardPayload);

							const threeDSecureDataValidationPayload =
								btStoredCardPayload.threeDSecureInfo;

							if (threeDSecureDataValidationPayload) {
								creditCardPaymentProcessingHelper.fill3DsData(
									threeDSecureDataValidationPayload
								);
							}

							return Promise.resolve(btStoredCardPayload);
						});
				});
		});
};

/**
 * Processes a new card
 * @param {Object} btPayload Payload from Braintree
 * @returns {Promise} A promise
 */
const processNewCard = (btPayload) => {
	const isSessionPaymentsEnabled =
		window.braintreePreferences.isSessionPaymentsEnabled;

	// Fills a credit card billing address input with billing address data
	creditCardBillingAddressHelper.setCreditCardBillingAddress(
		creditCardBillingAddressHelper.createCreditCardBillingAddress()
	);

	document.getElementById('braintreeCreditCardBin').value =
		btPayload.btTokenizePayload.details.bin;

	// Fill hidden inputs CreditCard fields and Session CreditCard div fields
	creditCardPaymentProcessingHelper.fillCreditCardFormWithPayloadData(
		btPayload
	);

	// Filling of session card attributes for using them in business logic (client/server)
	creditCardPaymentProcessingHelper.setCreditCardDataAttributes(
		btPayload,
		isSessionPaymentsEnabled
	);

	if (isSessionPaymentsEnabled) {
		const creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');

		// Fill 'toDisplay' fields with data to display them for buyer
		creditCardPaymentProcessingHelper.fillSessionCreditCardFormWithPayloadData(
			btPayload
		);
		// Select "Session Account Option" in "Account List"
		creditCardPaymentProcessingHelper.markAsSelectedSessionAccount();
		// Display "Stored Accounts" list
		creditCardPaymentProcessingHelper.displayStoredCreditCardList();
		// Show "Session Credit Card" fields (in div blocks)
		creditCardFormFieldHelper.showCardElements(
			creditCardFormFieldHelper
				.getCCFieldsToDisplay()
				.asArray.concat(
					creditCardFormFieldHelper.getCcCvvToDisplayField().asArray
				)
		);
		// Hide hosted fields (iframe fields)
		creditCardFormFieldHelper.hideCardElements(
			creditCardFormFieldHelper
				.getCCFields()
				.asArray.concat(creditCardFormFieldHelper.getCcCvvField().asArray)
		);
	}

	// Clear "Hosted Fields" (from iframe)
	hfInstance.clearHostedFields();

	helper.continueButtonToggle(false);

	return Promise.resolve(btPayload);
};

/**
 * Process Credit Card on the Billing Page
 * @param {Object} event Event object
 * @returns {void}
 */
function processCreditCard(event) {
	event.preventDefault();
	event.stopPropagation();

	// Removes error messages on the Billing Page
	alertHandler.hideAlerts();

	try {
		const customErrorMessages = helper.tryParseJSON(
			$customCreditCardErrorContainer.getAttribute('data-errors')
		);
		const basketDataValidResponse = btCreditCardModel.isBasketDataValid(
			customErrorMessages.TOTAL_BASKET_AMOUNT_INVALID
		);
		const clientInstancePromise = btCreditCardModel.getClientInstancePromise();
		const creditCardFlow =
			creditCardPaymentProcessingHelper.getCreditCardFlowID($creditCardList);

		if (basketDataValidResponse.error) {
			throw basketDataValidResponse.customErrorMessage;
		}

		// Data passed inside "braintreeCreditCardModel"
		const billingData = helper.getBillingAddressFormValues();
		const email = document.querySelector('.customer-summary-email').textContent;
		const isCheckoutFlow = true;

		let result;

		// "Hosted Field Instance" validation
		const sdkHfInstanceValResult = hfInstance.sdkHfInstanceValidation();

		if (!sdkHfInstanceValResult.exist) {
			throw sdkHfInstanceValResult.errorMessage;
		}

		loader.show();

		const $billingForm = document.getElementById('dwfrm_billing');

		helper.clearForm($billingForm);

		switch (creditCardFlow) {
			// Case for new card
			case creditCardPaymentProcessingConstants.FLOW_NEW_CARD_NAME:
				result = btCreditCardModel
					.processNewCard(email, billingData, isCheckoutFlow, hfInstance)
					.then((btPayload) => {
						checkForErrorInPayload(btPayload);

						if (!helper.validateForm($billingForm)) {
							loader.hide();

							return Promise.reject(customErrorMessages.VALIDATION_INVALID);
						}

						const $saveCardCheckbox = document.getElementById(
							'braintreeSaveCreditCard'
						);

						if ($saveCardCheckbox && $saveCardCheckbox.checked) {
							const response = helper.checkForDuplicatedCC(btPayload);

							if (response.error) {
								return Promise.reject(response.message);
							}
						}

						return btPayload;
					})
					.then((btPayload) => processNewCard(btPayload));

				break;

			// Case for session card
			case creditCardPaymentProcessingConstants.FLOW_SESSION_CARD_NAME:
				result = btCreditCardModel.processSessionCard();

				break;

			// Case for stored card
			case creditCardPaymentProcessingConstants.FLOW_STORED_CARD_NAME:
				result = processStoredCard(clientInstancePromise, email, billingData);

				break;
			default:
				// Handle error with wrong checkout flow
				result = Promise.reject(customErrorMessages.WROND_CHECKOUT_FLOW);

				break;
		}

		result
			.then(function () {
				loader.hide();
				// Trigger "Submit Payment" button
				event.target.click();
			})
			.catch(function (error) {
				loader.hide();

				// Braintree Errors
				alertHandler.handleCreditCardError(error, hfInstance.getCcFields());

				helper.validateForm($billingForm);
			});
	} catch (error) {
		loader.hide();
		alertHandler.handleCreditCardError(error);
	}
}

/**
 * Inits Credit Card process on the Billing Page
 * @param {Constructor} alertHandlerModel Alert handling constructor
 * @param {Object} hostedFieldsInstance A hosted fields instance
 */
function init(alertHandlerModel, hostedFieldsInstance) {
	loader = loaderInstance($braintreeCreditCardLoader);
	alertHandler = alertHandlerModel;
	hfInstance = hostedFieldsInstance;

	$continueButton.addEventListener('click', function (event) {
		const isCreditCardTabActive =
			creditCardPaymentProcessingHelper.isActiveCreditCardTab();

		// Removes active session payment method once 'Next: Place order' button clicked
		helper.removeActiveSessionPayment();

		if (!event.isTrusted || !isCreditCardTabActive) {
			return;
		}

		processCreditCard(event);
	});
	$continueButtonNew.addEventListener('click', function (event) {
		const isCreditCardTabActive =
			creditCardPaymentProcessingHelper.isActiveCreditCardTab();

		// Removes active session payment method once 'Next: Place order' button clicked
		helper.removeActiveSessionPayment();

		if (!event.isTrusted || !isCreditCardTabActive) {
			return;
		}

		processCreditCard(event);
	});
}

module.exports = {
	init,
};

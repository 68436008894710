'use strict';

const loaderInstance = require('../../loaderHelper');
const venmoButton = require('./venmoButton');
const venmoList = require('./venmoList');
const venmoHelper = require('../helpers/venmoHelper');
const venmoSessionAccount = require('./venmoSessionAccount');
const helper = require('../../helper');

const $braintreeVenmoNonce = document.getElementById('braintreeVenmoNonce');
const $braintreeVenmoUserId = document.getElementById('braintreeVenmoUserId');
const $sessionVenmoAccount = document.getElementById('sessionVenmoAccount');
const $newVenmoAccount = document.getElementById('newVenmoAccount');
const $venmoBillingInput = document.getElementById('braintreeVenmoBilling');
const $venmoContent = document.querySelector('.js-braintree-venmo-content');
const $venmoButton = document.querySelector('.js-braintree-venmo-button');
const $venmoAccountContainer = document.querySelector(
	'.js-braintree-used-venmo-account'
);
const $continueButton = document.querySelector('button.submit-payment');
const $submitShippingBtn = document.querySelector('.button, .submit-shipping');

const dataAttrIsValidStage = 'data-is-valid-stage';

let hashChange;

/**
 * Process Venmo on the Billing/Checkout Page
 * @param {Object} data tokenized Venmo payload data
 */
function processCheckoutFlow(data) {
	const $loaderContainer = venmoHelper.createLoaderContainter(
		document.querySelector('.page')
	);

	loaderInstance($loaderContainer).show();

	$braintreeVenmoNonce.value = data.nonce;
	$braintreeVenmoUserId.value = data.details.username;
	// In case when stage is correct we proceed with usual flow
	if (window.history.state !== 'shipping') {
		$continueButton.click();
		document.querySelector('.js-braintree-venmo-loader').remove();

		return;
	}

	// In case when Venmo has returned from Venmo app to NOT correct stage (for example, to shipping stage)
	// we redirect user to correct stage and set required data
	venmoHelper.updateBillingAddressFormValues(
		helper.tryParseJSON($venmoBillingInput.value)
	);
	// Move to stage payment
	$submitShippingBtn.click();
	$venmoButton.setAttribute(dataAttrIsValidStage, true);
	document.querySelector('.js-braintree-venmo-loader').remove();
}

/**
 * Inits Venmo checkout components
 * @param {Object} btVenmoModel Braintree Venmo model
 */
function init(btVenmoModel) {
	// MutationObserver is triggered on each URL change to avoid conflict between URL passed to Venmo application
	// and URL with already changed stage on storefront (checkout stage)
	// case when user is redirected from Venmo application
	hashChange = new MutationObserver(function (mutations) {
		let result = false;

		const isSessionPaymentsEnabled =
			window.braintreePreferences.isSessionPaymentsEnabled;
		const mutation = mutations.find(function (element) {
			return (
				element.attributeName === 'data-checkout-stage' &&
				element.target.dataset.checkoutStage === 'payment'
			);
		});

		if (!mutation) {
			return result;
		}

		if (
			$braintreeVenmoNonce.value &&
			helper.tryParseJSON($venmoButton.getAttribute(dataAttrIsValidStage))
		) {
			$venmoButton.setAttribute(dataAttrIsValidStage, false);
			$continueButton.click();

			result = true;

			return result;
		}

		// Case when user has proceeded with Venmo session account and clicked on "edit" button
		if (
			isSessionPaymentsEnabled &&
			$venmoContent.classList.contains('active') &&
			mutation.oldValue === 'placeOrder'
		) {
			// Show dropdown with used Venmo session account
			venmoSessionAccount.showVenmoAccount();

			if ($braintreeVenmoUserId && $braintreeVenmoUserId.value !== 'Venmo') {
				$venmoAccountContainer.classList.remove('used-venmo-account-hide');
				$venmoAccountContainer.classList.add('js-used-venmo-account');
				$sessionVenmoAccount.classList.remove('used-venmo-account-hide');
				$sessionVenmoAccount.classList.add('js-used-venmo-account');
				$sessionVenmoAccount.textContent = $braintreeVenmoUserId.value;
				$sessionVenmoAccount.selected = true;
				$newVenmoAccount.selected = false;
			}

			// Set attribute to show "place order" button
			$venmoContent.setAttribute('data-paypal-is-hide-continue-button', false);
			// Hide Venmo button
			$venmoButton.setAttribute('data-is-hide-venmo-button', true);
		}

		return result;
	});

	hashChange.observe(document.getElementById('checkout-main'), {
		attributeOldValue: true,
	});

	// Venmo button functionality is initiated once in case if it was not inited yet
	// based on existence of window.venmoCheckoutButtonInited (set while Venmo button initiation)
	if (!window.venmoCheckoutButtonInited) {
		venmoButton.init(btVenmoModel, $venmoButton, true);
		venmoList.init();
	}

	const sessionPaymentMethodId = helper.tryParseJSON(
		$venmoContent.getAttribute('data-braintree-config')
	).sessionPaymentMethodId;
	// if exists Venmo session account - we make Venmo tab active on page reload

	if (sessionPaymentMethodId === 'Venmo') {
		document.querySelector('.venmo-tab').click();
	}
}

module.exports = {
	init,
	processCheckoutFlow,
};

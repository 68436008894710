'use strict';

/**
 * This file was created to provide functionality and business logic for Credit and SRC payment methods. Do not expand this file!
 * Changes and additions to the code should be made to the appropriate files in the folders, depending on the payment method required, to maintain a clear code architecture.
 */

const AlertHandlerModel = require('./alertHandler');
const helper = require('./helper');

const cssClass = {
	D_NONE: 'd-none',
	FONT_WEIGHT_BOLD: 'font-weight-bold',
};

const errorHandler = new AlertHandlerModel();
let creditCardListLoader;

/**
 * Shows the new card block on the Account Page
 */
function showAddNewCardOptions() {
	const $addNewCardButton = document.querySelector(
		'.js-braintree-add-new-card-btn'
	);
	const $addNewCardButtonWrapper = document.querySelector(
		'.js-braintree-add-new-card-wrapper-btn'
	);
	const $addNewCardBlock = document.querySelector(
		'.js-braintree-add-new-card-block'
	);

	// Hide "Credit Card error message" container
	errorHandler.hideAlerts();
	// Hide "Add New Card" button
	$addNewCardButton.classList.add(cssClass.D_NONE);
	$addNewCardButtonWrapper.classList.add(cssClass.D_NONE);
	// Display "Add New Card" form
	$addNewCardBlock.classList.remove(cssClass.D_NONE);
}

/**
 * Removes card from Credit/SRC Cards list
 * @param {Object} e Event object
 */
function initRemoveCardEvent() {
	const $removeCardBtns = document.querySelectorAll(
		'.js-credit-card-accounts .js-remove-bt-payment'
	);

	$removeCardBtns.forEach((el) =>
		el.addEventListener('click', function (e) {
			const target = e.target;

			creditCardListLoader.show();

			fetch(
				helper.getUrlWithCsrfToken(
					`${window.braintreeUrls.deletePaymentUrl}?UUID=${target.getAttribute(
						'data-id'
					)}`
				),
				{ method: 'GET' }
			)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.error && data.error.message) {
						// Show error message in a case of current PI removal is not allowed
						errorHandler.showError(data.error.message);
					} else {
						document.getElementById(`uuid-${data.UUID}`).remove();

						if (data.newDefaultAccount) {
							document
								.getElementById(`uuid-${data.newDefaultAccount}`)
								.classList.add(cssClass.FONT_WEIGHT_BOLD);
							document
								.getElementsByClassName(`uuid-${data.newDefaultAccount}`)
								.classList.add(cssClass.D_NONE);
						}
					}

					creditCardListLoader.hide();
				})
				.catch(() => {
					location.reload();
				});
		})
	);
}

/**
 * Select default card in Credit/SRC Cards list
 * @param {Object} e Event object
 */
function initMakeDefaultCardEvent() {
	const $makeDefaultCardBtns = document.querySelectorAll(
		'.js-credit-card-accounts .js-braintree-make-default-card'
	);

	$makeDefaultCardBtns.forEach((el) =>
		el.addEventListener('click', function (e) {
			const target = e.target;
			const url = window.braintreeUrls.makePaymentMethodDefaultUrl;
			const id = target.getAttribute('data-id');
			const paymentMethodID = 'CARD';

			// Hide "Credit Card error message" container
			errorHandler.hideAlerts();
			creditCardListLoader.show();

			fetch(
				helper.getUrlWithCsrfToken(`${url}?UUID=${id}&pmID=${paymentMethodID}`)
			)
				.then((response) => response.json())
				.then((data) => {
					// New default Card changes
					document
						.querySelector(`#uuid-${data.newDefaultProperty} span`)
						.classList.add(cssClass.FONT_WEIGHT_BOLD);
					document
						.querySelector(
							'.js-braintree-make-default-card.uuid-' + data.newDefaultProperty
						)
						.classList.add(cssClass.D_NONE);

					// Previous default Card changes
					document
						.querySelector(`#uuid-${data.toRemoveDefaultProperty} span`)
						.classList.remove(cssClass.FONT_WEIGHT_BOLD);
					document
						.querySelector(
							'.js-braintree-make-default-card.uuid-' +
								data.toRemoveDefaultProperty
						)
						.classList.remove(cssClass.D_NONE);

					creditCardListLoader.hide();
				})
				.catch(() => {
					creditCardListLoader.hide();
				});
		})
	);
}

/**
 * Inits braintree account page functionality
 */
function initAccount() {
	const loaderInstance = require('./loaderHelper');

	const $creditCardAccountBtLoader = document.getElementById(
		'creditCardAccountBtLoader'
	);
	const $addNewCard = document.querySelector('.js-braintree-add-new-card-btn');
	const $submitCwppUnlinkBtn = document.querySelector('.js-unlink-submit');
	const $unlinkSection = document.querySelector('.js-unlink-section');

	const creditCardListLoaderExists = Boolean($creditCardAccountBtLoader);

	if (creditCardListLoaderExists) {
		creditCardListLoader = loaderInstance($creditCardAccountBtLoader);
	}

	if ($addNewCard) {
		// "Add new card" area link functionality initiation
		$addNewCard.addEventListener('click', showAddNewCardOptions);
	}

	// User unlinking functionality initiation
	if ($submitCwppUnlinkBtn && $unlinkSection) {
		$submitCwppUnlinkBtn.addEventListener('click', () => {
			fetch(helper.getUrlWithCsrfToken(window.braintreeUrls.unlinkCwppUrl), {
				method: 'POST',
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.errorMessage) {
						errorHandler.showError(res.errorMessage);
					} else {
						$unlinkSection.classList.add('d-none');

						errorHandler.showInfo(res.alertMessage);
					}
				})
				.catch(() => {
					location.reload();
				});
		});
	}

	// "Make default" link functionality initiation
	initMakeDefaultCardEvent();

	// "Remove Card" link functionality initiation
	initRemoveCardEvent();
}

module.exports = {
	initAccount,
	initRemoveCardEvent,
	initMakeDefaultCardEvent,
};

'use strict';

const braintreeGooglePayModel = require('./braintreeGooglePayModel');
const braintreeGeneral = require('../../braintreeGeneral');
const googlePayHelper = require('../helpers/googlePayHelper');

const googlePayErrorMessages = require('../constants/errorMessages');

/**
 * Initialize Braintree Google Pay Model. Pattern Builder is used
 * @returns {Object} Object with booleam "error" flag and "message" attribute with error message
 */
function init() {
	const braintreeGooglePayConfigs =
		googlePayHelper.getBraintreeGooglePayConfigs();
	const response = {
		error: false,
		message: '',
		btGooglePayModel: null,
		btGooglePayConfigs: null,
	};

	let clientToken;
	let btClientInstancePromise;

	if (braintreeGooglePayConfigs) {
		clientToken = braintreeGooglePayConfigs.clientToken;
		btClientInstancePromise =
			braintreeGeneral.createClientInstance(clientToken);

		braintreeGooglePayModel.init(
			btClientInstancePromise,
			braintreeGooglePayConfigs.isFraudToolsEnabled
		);

		response.btGooglePayModel = braintreeGooglePayModel;
		response.btGooglePayConfigs = braintreeGooglePayConfigs;
	} else {
		response.error = true;
		response.message =
			googlePayErrorMessages.NO_GOOGLE_PAY_BRAINTREE_CONFIGURATIONS;
	}

	return response;
}

module.exports = {
	init,
};

'use strict';

const braintreeVenmoModel = require('./braintreeVenmoModel');
const braintreeGeneral = require('../../braintreeGeneral');
const venmoHelper = require('../helpers/venmoHelper');

/**
 * Initialize Braintree Venmo Model. Pattern Builder is used
 * @returns {Object} object with boolean "error" flag and "message" attribute with error message
 */
function init() {
	const braintreeVenmoButtonConfigs =
		venmoHelper.getBraintreeVenmoButtonConfigs();
	const response = {
		error: false,
		message: '',
		btVenmoModel: null,
	};

	let clientToken;
	let btClientInstancePromise;

	if (braintreeVenmoButtonConfigs) {
		clientToken = braintreeVenmoButtonConfigs.clientToken;
		btClientInstancePromise =
			braintreeGeneral.createClientInstance(clientToken);

		braintreeVenmoModel.init(btClientInstancePromise);

		response.btVenmoModel = braintreeVenmoModel;
	} else {
		response.error = true;
		response.message = 'No Braintree Venmo Configurations';
	}

	return response;
}

module.exports = {
	init,
};

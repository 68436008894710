'use strict';

module.exports = {
	NO_GOOGLE_PAY_BRAINTREE_CONFIGURATIONS:
		'No Google Pay Braintree Configurations',
	ORDER_TOTAL_0: 'Order total 0 is not allowed for GooglePay',
	GOOGLE_PAY_WINDOW_CLOSED: 'User closed the Payment Request UI.',
	SHIPPING_ADDRESS_UNSERVICEABLE:
		'Cannot ship to the selected address. Please choose another shipping address or shipping method.',
	SHIPPING_OPTION_INVALID:
		'This shipping option is invalid for the given address',
};

'use strict';

const googlePayHelper = require('../helpers/googlePayHelper');

/**
 * Inits Google Pay list components
 */
function init() {
	const $googlepayCardDescription = document.getElementById(
		'braintreeGooglePayCardDescription'
	);
	const $btGooglepayAccountsList = document.getElementById(
		'braintreeGooglepayAccountsList'
	);
	const googlePayAccountListExist = Boolean($btGooglepayAccountsList);

	if (googlePayAccountListExist) {
		$btGooglepayAccountsList.addEventListener('change', function () {
			googlePayHelper.googlePayContentTabHandler(true);
		});
	}

	if ($googlepayCardDescription.value === 'GooglePay') {
		$googlepayCardDescription.value =
			$btGooglepayAccountsList.selectedOptions[0].label;
	}
}

module.exports = {
	init,
};

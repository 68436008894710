'use strict';

const lpmHelper = require('../helpers/lpmHelper');
const braintreeGeneral = require('../../braintreeGeneral');
const braintreeLpmModel = require('./braintreeLpmModel');

const lpmErrorMessages = require('../constants/errorMessages');

/**
 * Initialize Braintree Lpm Model. Pattern Builder is used
 * @returns {Object} Object with booleam "error" flag and "message" attribute with error message
 */
function init() {
	const braintreeLpmConfigs = lpmHelper.getBraintreeLpmConfigs();
	const response = {
		error: false,
		message: '',
		btLpmModel: null,
	};

	let clientToken;
	let btClientInstancePromise;

	if (braintreeLpmConfigs) {
		clientToken = braintreeLpmConfigs.clientToken;
		btClientInstancePromise =
			braintreeGeneral.createClientInstance(clientToken);

		braintreeLpmModel.init(btClientInstancePromise);

		response.btLpmModel = braintreeLpmModel;
	} else {
		response.error = true;
		response.message = lpmErrorMessages.NO_LPM_BRAINTREE_CONFIGURATIONS;
	}

	return response;
}

module.exports = {
	init,
};

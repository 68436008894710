'use strict';

const venmoConstants = require('../constants/venmoConstants');

/**
 * Gets Venmo flow name (corresponding page)
 * @returns {string} Flow ID (page) Account/Billing
 */
function getVenmoFlowName() {
	const isVenmoEnabledOnAccountPage = Boolean(
		document.querySelector('.js-braintree-account-venmo-button')
	);
	const isVenmoEnabledOnBillingPage = Boolean(
		document.querySelector('.js-braintree-venmo-content')
	);

	let venmoFlowName;

	if (isVenmoEnabledOnAccountPage) {
		venmoFlowName = venmoConstants.FLOW_NAME_ACCOUNT;
	} else if (isVenmoEnabledOnBillingPage) {
		venmoFlowName = venmoConstants.FLOW_NAME_BILLING_CHECKOUT;
	}

	return venmoFlowName;
}

module.exports = {
	getVenmoFlowName,
};

'use strict';

const creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');
const creditCardHelper = require('../helpers/creditCardHelper');
const paymentMethodGeneralHelper = require('../../paymentMethodGeneralHelper');
const creditCardBillingAddressHelper = require('../helpers/creditCardBillingAddressHelper');

let hostedFieldsInstance;

/**
 * Makes the necessary setting for 'Save Credit Card block' on the Billing Page
 * @param {boolean} saveCreditCard true/false
 * @param {boolean} displaySaveCreditCardBlock true/false
 */
function saveCreditCardBlockManipulation(
	saveCreditCard,
	displaySaveCreditCardBlock
) {
	const $saveCreditCard = document.getElementById('braintreeSaveCreditCard');
	const $braintreeSaveCardContainer = document.getElementById(
		'braintreeSaveCardContainer'
	);

	if ($braintreeSaveCardContainer) {
		$braintreeSaveCardContainer.style.display = displaySaveCreditCardBlock
			? 'block'
			: 'none';
		$saveCreditCard.checked = saveCreditCard;
	}
}

/**
 * Handles changing of Credit Card list on the Billing Page
 * @param {Constructor} alertHandlerModel Alert handling model
 */
function cardListChange(alertHandlerModel) {
	const btUtils = require('../../braintreeUtils');

	const $creditCardList =
		creditCardFormFieldHelper.getCreditCardListContainer();
	const $braintreeCreditCardNonce =
		creditCardFormFieldHelper.getCreditCardNonceFieldContainer();
	const $braintreeCardPaymentMethod = document.getElementById(
		'braintreeCardPaymentMethod'
	);
	const creditCardOption = $creditCardList.selectedOptions[0];
	const creditCardOptionID = creditCardOption.id;
	const creditCardFiledsToDisplayObject =
		creditCardFormFieldHelper.getCCFieldsToDisplay().asObject;

	let creditCardFieldsToDisplayArray =
		creditCardFormFieldHelper.getCCFieldsToDisplay().asArray;
	let creditCardHostedFieldsArray =
		creditCardFormFieldHelper.getCCFields().asArray;
	// Variables only for "braintreeSessionCreditAccount" case
	let selectedCreditCard;
	let nonce = '';
	let saveCreditCard = true;
	let showSaveCreditCardBlock = true;

	const isReVerifyFlow =
		hostedFieldsInstance.hostedFieldsConfigs.isCcReVerifyEnabled &&
		hostedFieldsInstance.hostedFieldsConfigs.isSavedCreditCard;

	const ccCvvToDisplayField =
		creditCardFormFieldHelper.getCcCvvToDisplayField();
	const ccCvvField = creditCardFormFieldHelper.getCcCvvField();
	const $selectedCcAccountOption =
		creditCardHelper.getSelectedCcAccountOption();
	const savedBillingAddress = $selectedCcAccountOption.getAttribute(
		'data-billing-address'
	);
	const isCcReVerifyRequired = hostedFieldsInstance.isCcReVerifyRequired(
		$selectedCcAccountOption.value
	);

	// Clear error messages on the Billing Page
	alertHandlerModel.hideAlerts();

	if (!savedBillingAddress) {
		paymentMethodGeneralHelper.enableBillingAddressFunctionality();
	}

	creditCardBillingAddressHelper.displaySelectedStoredBillingAddress(
		$selectedCcAccountOption
	);

	// Shows or hides a billing info alert for Credit card payment
	creditCardBillingAddressHelper.initBillingInfoAlert(savedBillingAddress);

	switch (creditCardOptionID) {
		// When buyer selected "new cc"
		case 'newCardAccount':
			// Initiates a basic hosted fields for new card only
			if (hostedFieldsInstance.sdkHfInstance && isReVerifyFlow) {
				const { basicFlowConfigs, styles } =
					hostedFieldsInstance.getFieldsOptions();

				hostedFieldsInstance.teardown();
				// Sets an appropriate hosted fields options object for Checkout page
				hostedFieldsInstance.setHostedFieldsOptions({
					styles: styles,
					configs: basicFlowConfigs,
				});
				// Initiates the appropriate hosted fileds for new Card account
				hostedFieldsInstance.initHostedFields();
			}

			// Hide 'toDisplay' fields
			creditCardFormFieldHelper.hideCardElements(
				creditCardFieldsToDisplayArray.concat(ccCvvToDisplayField.asArray)
			);

			creditCardFormFieldHelper.showCardElements(
				creditCardHostedFieldsArray.concat(ccCvvField.asArray)
			);

			// Display "Save Credit Card" block, and mark it as checked
			saveCreditCardBlockManipulation(saveCreditCard, showSaveCreditCardBlock);

			$braintreeCreditCardNonce.value = '';
			$braintreeCardPaymentMethod.value = 'CREDIT';

			break;

		case 'braintreeSessionCreditAccount':
			selectedCreditCard = btUtils.getSelectedData($creditCardList);
			nonce = selectedCreditCard['data-nonce'].value;
			saveCreditCard = selectedCreditCard['data-save-card'].value;

			// Display "Save Credit Card" block, and mark it as checked
			saveCreditCardBlockManipulation(saveCreditCard, showSaveCreditCardBlock);

			creditCardFiledsToDisplayObject.cardCvvToDisplay =
				ccCvvToDisplayField.asObject.cardCvvToDisplay;

			// Add values to the "toDisplay" and hidden input fields.
			creditCardFormFieldHelper.setCardFields(
				selectedCreditCard,
				creditCardFiledsToDisplayObject
			);

			creditCardFormFieldHelper.hideCardElements(
				creditCardHostedFieldsArray.concat(ccCvvField.asArray)
			);
			creditCardFormFieldHelper.showCardElements(
				creditCardFieldsToDisplayArray.concat(ccCvvToDisplayField.asArray)
			);

			$braintreeCreditCardNonce.value = nonce;
			$braintreeCardPaymentMethod.value =
				selectedCreditCard['data-payment-method'].value.toUpperCase();

			break;

		// Case for saved credit cards
		default:
			if (hostedFieldsInstance.sdkHfInstance && isCcReVerifyRequired) {
				const { reverifyFlowConfigs, styles } =
					hostedFieldsInstance.getFieldsOptions();

				hostedFieldsInstance.teardown();
				// Sets an appropriate hosted fields options object for Checkout page
				hostedFieldsInstance.setHostedFieldsOptions({
					styles: styles,
					configs: reverifyFlowConfigs,
				});
				// Initiates the appropriate hosted fileds for new Card account
				hostedFieldsInstance.initHostedFields();
			}

			saveCreditCard = false;
			showSaveCreditCardBlock = false;
			selectedCreditCard = btUtils.getSelectedData($creditCardList);

			if (isCcReVerifyRequired) {
				creditCardFieldsToDisplayArray = creditCardFieldsToDisplayArray.concat(
					ccCvvField.asArray
				);
				creditCardHostedFieldsArray = creditCardHostedFieldsArray.concat(
					ccCvvToDisplayField.asArray
				);
			} else {
				creditCardFieldsToDisplayArray = creditCardFieldsToDisplayArray.concat(
					ccCvvToDisplayField.asArray
				);
				creditCardHostedFieldsArray = creditCardHostedFieldsArray.concat(
					ccCvvField.asArray
				);
			}

			// Add values to the 'toDisplay' and hidden input fields
			creditCardFormFieldHelper.setCardFields(
				selectedCreditCard,
				creditCardFiledsToDisplayObject
			);

			// Show these fields to the buyer
			creditCardFormFieldHelper.showCardElements(
				creditCardFieldsToDisplayArray
			);

			// Hide hosted fields (since we don't need them)
			creditCardFormFieldHelper.hideCardElements(creditCardHostedFieldsArray);

			// Display "Save Credit Card" block, and uncheck it
			saveCreditCardBlockManipulation(saveCreditCard, showSaveCreditCardBlock);

			if (savedBillingAddress) {
				paymentMethodGeneralHelper.disableBillingAddressFunctionality();
			}

			$braintreeCreditCardNonce.value = nonce;
			$braintreeCardPaymentMethod.value =
				selectedCreditCard['data-payment-method'].value.toUpperCase();

			break;
	}
}

/**
 * Inits Credit Card list functionality
 * @param {Object} btCreditCardModel BT Credit Card model
 * @param {Constructor} alertHandlerModel alertHandlerModel Error handling model
 * @param {Object} hfInstance A Hosted fields instance
 */
function init(btCreditCardModel, alertHandlerModel, hfInstance) {
	const $creditCardList = document.getElementById('braintreeCreditCardList');
	const creditCardListExist = Boolean($creditCardList);

	hostedFieldsInstance = hfInstance;

	if (creditCardListExist) {
		$creditCardList.addEventListener('change', function () {
			cardListChange(alertHandlerModel);
		});
	}

	cardListChange(alertHandlerModel);
}

module.exports = {
	init,
	cardListChange,
};

'use strict';

const AlertHandlerModel = require('../../alertHandler');

/**
 * ApplePay alert handler model
 */
class ApplePayAlertHandlerModel extends AlertHandlerModel {
	constructor(alertMessages) {
		super();
		this.alertMessages = alertMessages;
	}

	/**
	 * Handles an error by type
	 * @param {Object} error Error object or error message
	 */
	handleApplePayError(error) {
		if (error.name === 'BraintreeError') {
			this.showBraintreeError(error);
		} else {
			this.showError(error.message);
		}
	}
}

module.exports = ApplePayAlertHandlerModel;

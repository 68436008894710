'use strict';

// Braintree SDK model builder, we need to init Braintree Credit Card model on this level, since we are using it on step of "creditCardFields.init()"
// and on step of "creditCardPayment.init()".

const creditCardFields = require('./components/creditCardFields');

const HostedFieldsModel = require('./braintreesdk/hostedFieldsModel');
const CreditCardAlertHandlerModel = require('../creditcard/alertHandler/creditCardAlertHandlerModel');

const hostedFieldsInstance = new HostedFieldsModel();

let btCreditCardModelResponse;
let creditCardMessages;

/**
 * Inits Credit Card functionality on the Account Page
 * @returns {void}
 */
function initAccount() {
	const creditCardAccount = require('./components/creditCardAccount');

	const alertHandler = new CreditCardAlertHandlerModel(creditCardMessages);
	const { basicFlowConfigs, styles } = hostedFieldsInstance.getFieldsOptions();

	// Sets an appropriate hosted fields options object for Account page
	hostedFieldsInstance.setHostedFieldsOptions({
		styles: styles,
		configs: basicFlowConfigs,
	});

	if (!btCreditCardModelResponse || btCreditCardModelResponse.error) {
		alertHandler.handleCreditCardError(btCreditCardModelResponse.message);
	}

	creditCardAccount.init(
		btCreditCardModelResponse.btCreditCardModel,
		alertHandler,
		creditCardMessages,
		hostedFieldsInstance
	);

	creditCardFields.init(
		btCreditCardModelResponse.btCreditCardModel,
		alertHandler,
		hostedFieldsInstance
	);
}

/**
 * Inits Credit Card functionality on the Checkout Page
 * @returns {void}
 */
async function initCheckout() {
	const creditCardList = require('./components/creditCardList');
	const creditCardPaymentProcessing = require('./components/creditCardPaymentProcessing');

	const alertHandler = new CreditCardAlertHandlerModel(creditCardMessages);
	// Hosted fields options initializations
	const fieldsOptions = hostedFieldsInstance.getFieldsOptions();
	const isReVerifyFlow =
		hostedFieldsInstance.hostedFieldsConfigs.isCcReVerifyEnabled &&
		hostedFieldsInstance.hostedFieldsConfigs.isSavedCreditCard;
	const configs = isReVerifyFlow
		? fieldsOptions.reverifyFlowConfigs
		: fieldsOptions.basicFlowConfigs;

	// Sets an appropriate hosted fields options object for Checkout page
	hostedFieldsInstance.setHostedFieldsOptions({
		styles: fieldsOptions.styles,
		configs,
	});

	if (!btCreditCardModelResponse || btCreditCardModelResponse.error) {
		alertHandler.handleCreditCardError(btCreditCardModelResponse.message);
	}

	// Call server to update data from basket
	await btCreditCardModelResponse.btCreditCardModel.updateOrderData();

	creditCardFields.init(
		btCreditCardModelResponse.btCreditCardModel,
		alertHandler,
		hostedFieldsInstance
	);

	creditCardList.init(
		btCreditCardModelResponse.btCreditCardModel,
		alertHandler,
		hostedFieldsInstance
	);

	creditCardPaymentProcessing.init(alertHandler, hostedFieldsInstance);
}

/**
 * Inits 'braintreeCreditCardModelBuilder' on creditCard level
 */
function init() {
	const braintreeCreditCardModelBuilder = require('./braintreesdk/braintreeCreditCardModelBuilder');

	btCreditCardModelResponse = braintreeCreditCardModelBuilder.init();

	if (!btCreditCardModelResponse.error) {
		creditCardMessages = btCreditCardModelResponse.btCreditCardConfigs.messages;
	}
}

module.exports = {
	initAccount,
	initCheckout,
	init,
};

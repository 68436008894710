'use strict';

const helper = require('../../helper');

/**
 * Uncheck Save Credit Card checkbox
 * @param {boolean} save true/false
 */
function saveCreditCard(save) {
	const isSave = save ? true : false; // eslint-disable-line

	document.getElementById('braintreeSaveCreditCard').checked = isSave;
}

/**
 * Hides or Display 'braintreeSaveCardContainer' container
 * @param {boolean} hide true/false
 */
function hideSaveCreditCardBlock(hide) {
	const isHide = hide ? true : false; // eslint-disable-line

	document.getElementById('braintreeSaveCardContainer').style.display = isHide
		? 'none'
		: 'block';
}

/**
 * Returns Braintree Credit Card configs
 * @returns {Object} Braintree Credit Card configs
 */
function getCheckoutBraintreeCreditCardConfigs() {
	let braintreeCreditCardFieldsConfigs = null;

	try {
		braintreeCreditCardFieldsConfigs = helper.tryParseJSON(
			document
				.querySelector('.js-braintree-credit-card-fields')
				.getAttribute('data-braintree-config')
		);
	} catch (error) {
		return braintreeCreditCardFieldsConfigs;
	}

	return braintreeCreditCardFieldsConfigs;
}

/**
 * Returns a selected credit card option
 * @returns {DomElement} A selected credit card option
 */
function getSelectedCcAccountOption() {
	const creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');
	const $creditCardList =
		creditCardFormFieldHelper.getCreditCardListContainer();

	return document.getElementById('braintreeCreditCardList').options[
		$creditCardList.selectedIndex
	];
}

/**
 * Returns a credit card billing address as string from data attribute
 * @returns {string} A credit card billing address as string
 */
function getSavedCcBillingAddressAsString() {
	return getSelectedCcAccountOption().getAttribute('data-billing-address');
}

module.exports = {
	getCheckoutBraintreeCreditCardConfigs,
	saveCreditCard,
	hideSaveCreditCardBlock,
	getSavedCcBillingAddressAsString,
	getSelectedCcAccountOption,
};

'use strict';

/**
 * Alert handler model
 */
class AlertHandlerModel {
	constructor() {
		this.$flashMessagesContainer = document.querySelector(
			'.js-flash-messages-container'
		);
		this.$alertTemplate = document.querySelector('.js-alert-template');
	}

	/**
	 * Creates an alert message
	 * @param {string} alertMessage alert message
	 * @param {string} alertType alert type, i.e. danger, warning, info etc
	 */
	createAlertMessage(alertMessage, alertType) {
		const $alertContainer = this.$alertTemplate.cloneNode(true);

		$alertContainer.classList.add(`alert-${alertType}`, 'show');
		$alertContainer.classList.remove('d-none');

		$alertContainer.append(alertMessage);
		this.$flashMessagesContainer.append($alertContainer);
	}

	/**
	 * ---- General method which should be used for cases when error came from Braintree ----
	 * Shows an error notification by message
	 * @param {string} errorMessage message to display inside error notification
	 */
	showError(errorMessage) {
		this.createAlertMessage(
			errorMessage,
			window.braintreeConstants.FLASH_MESSAGE_DANGER
		);
	}

	/**
	 * ---- General method which should be used for cases when error came from Braintree ----
	 * Shows an error notification by error Object from BT
	 * NOTE this.alertMessages come from a child class that has alertMessages as an argument
	 * @param {Object} error error object from BT
	 * @param {Object} errorMessages object with error messages
	 */
	showBraintreeError(error, errorMessages) {
		const message = error.message;
		const errorCode = error.code;
		const alertMessages = errorMessages || this.alertMessages;
		const errorMessage =
			alertMessages[errorCode] ||
			message ||
			alertMessages.CLIENT_GATEWAY_NETWORK;

		this.showError(errorMessage);
	}

	/**
	 * Shows a warning message
	 * @param {string} warningMessage warning message
	 */
	showWarning(warningMessage) {
		this.createAlertMessage(
			warningMessage,
			window.braintreeConstants.FLASH_MESSAGE_WARNING
		);
	}

	/**
	 * Shows a warning message
	 * @param {string} infoMessage warning message
	 */
	showInfo(infoMessage) {
		this.createAlertMessage(
			infoMessage,
			window.braintreeConstants.FLASH_MESSAGE_INFO
		);
	}

	/**
	 * Hides alert messages that are created by AlertHandler model
	 */
	hideAlerts() {
		const $alertContainers = document.querySelectorAll('.js-alert-template');

		$alertContainers.forEach((element) => element.classList.add('d-none'));
	}

	/**
	 * Hides alert messages that are created by FlashMessages controller
	 */
	hideControllerCreatedAlerts() {
		const $alertContainers = document.querySelectorAll('.js-alert-dismissible');

		$alertContainers.forEach((element) => element.classList.add('d-none'));
	}
}

module.exports = AlertHandlerModel;

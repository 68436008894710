'use strict';

/**
 * Creates an object of configuration for creating a Google Pay payment
 * @param {Client} btClientInstance A client instance
 * @param {string} googleMerchantId Google Pay merchant identifier
 * @returns {Object} Configuration object
 */
function createGooglePayOptions(btClientInstance, googleMerchantId) {
	const gpOptions = {
		client: btClientInstance,
		googlePayVersion: 2,
	};

	if (googleMerchantId !== '' && googleMerchantId !== null) {
		gpOptions.googleMerchantId = googleMerchantId;
	}

	return gpOptions;
}

/**
 * Gets selected shipping id
 * @returns {string} Selected shipping id
 */
function getSelectedShippingId() {
	const shippingMethods = document.getElementById('shippingMethods');
	const selectedShippingMethod =
		shippingMethods.options[shippingMethods.selectedIndex];

	return selectedShippingMethod.dataset.shippingId;
}

module.exports = {
	createGooglePayOptions,
	getSelectedShippingId,
};

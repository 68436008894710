'use strict';

module.exports = {
	getSelectedData: function (selectElement) {
		let option = null;

		if (!selectElement) {
			return option;
		}

		const options = selectElement.getElementsByTagName('option');

		for (let i = 0; i < options.length; i++) {
			option = options[i];

			if (option.selected) {
				break;
			}
		}

		return option.attributes;
	},
};

const LpmAlertHandlerModel = require('./alerthandler/lpmAlertHandlerModel');
const helper = require('../helper');

const $lpmFallback = document.querySelector('.js-lpm-fallback');
const $lpmLoader = document.querySelector('.js-braintree-loader-container');
const lpmData = helper.tryParseJSON(
	$lpmFallback.getAttribute('data-braintree-lpm')
);
const alertHandler = new LpmAlertHandlerModel();

/**
 * LPM fallback process
 */
const process = () => {
	braintree.client
		.create({
			authorization: lpmData.clientToken,
		})
		.then((clientInstance) => {
			$lpmLoader.style.display = 'block';

			return braintree.localPayment.create({
				client: clientInstance,
			});
		})
		.then((localPaymentInstance) => {
			localPaymentInstance
				.tokenize()
				.then(function ({ nonce, details }) {
					const buyerAddressDetails = Object.keys(details).length
						? details
						: { email: lpmData.customerEmail };

					alertHandler.showInfo(alertHandler.alertMessages.LPM_PAYMENT_SUCCESS);

					fetch(helper.getUrlWithCsrfToken(lpmData.paymentConfirmUrl), {
						method: 'POST',
						body: JSON.stringify({
							nonce: nonce,
							lpmName: lpmData.lpmName,
							details: buyerAddressDetails,
						}),
						headers: { 'Content-Type': 'application/json' },
					})
						.then((response) => response.json())
						.then((data) => {
							const lpmPaymentProcessorHelper = require('../local/helpers/lpmPaymentProcessorHelper');

							lpmPaymentProcessorHelper.processLpmConfirmForm(data.redirectUrl);
						});
				})
				.catch(() => {
					alertHandler.showError(alertHandler.alertMessages.LPM_PAYMENT_ERROR);
				});
		});
};

module.exports = {
	process,
};

'use strict';

const billingFormHelper = require('./payPalBillingFormHelperGlobal');
const paypalCheckoutModelHelper = require('./payPalCheckoutModelHelper');
const helper = require('../../helper');

/**
 * Is vault mode enabled
 * @param {Object} $paypalButton PayPal button js container
 * @returns {boolean} 'true' in case if vault mode is enabled
 */
function isVaultModeEnabled($paypalButton) {
	const payPalConfigurations = helper.tryParseJSON(
		$paypalButton.getAttribute('data-braintree-config')
	);

	return payPalConfigurations.vaultModeEnabled;
}

/**
 * Function which return account type.
 * Possible values are: new account, session account, stored account
 * @param {Object} $payPalAccountList PayPal account list js container
 * @param {Object} constantList Object with paypal account constants
 * @returns {string} Account type
 */
function getAccountType($payPalAccountList, constantList) {
	let accountType;

	if ($payPalAccountList.value === 'newaccount') {
		accountType = constantList.NEW_ACCOUNT;
	} else if (
		$payPalAccountList.selectedOptions[0].id === 'braintreePaypalAccount'
	) {
		accountType = constantList.SESSION_ACCOUNT;
	} else {
		accountType = constantList.STORED_ACCOUNT;
	}

	return accountType;
}

/**
 * Set nonce in to corresponding hidden input
 * @param {Object} $braintreePayPalNonce BT input nonce js container
 * @param {string} nonceValue Nonce
 */
function setNonceInputValue($braintreePayPalNonce, nonceValue) {
	$braintreePayPalNonce.value = nonceValue;
}

/**
 * Hide save PayPal account block
 * @param {Object} $savePaypalAccountCountainer Save PayPal account js container
 */
function hideSavePayPalAccountBlock($savePaypalAccountCountainer) {
	$savePaypalAccountCountainer.style.display = 'none';
}

/**
 * Display save PayPal account block
 * @param {Object} $savePaypalAccountCountainer Save PayPal account js container
 */
function displaySavePayPalAccountBlock($savePaypalAccountCountainer) {
	$savePaypalAccountCountainer.style.display = 'block';
}

/* --------------- PayPal button manipulations --------------- */
/**
 * Show PayPal button
 * @param {Object} $paypalButton PayPal button js container
 */
function displayPayPalButton($paypalButton) {
	$paypalButton.classList.remove('d-none');
}

/**
 * Hide PayPal button
 * @param {Object} $paypalButton PayPal button js container
 */
function hidePayPalButon($paypalButton) {
	$paypalButton.classList.add('d-none');
}

/* --------------- Continue button manipulations --------------- */
/**
 * Display Continue button
 * @param {Object} $continueButton Continue button js container
 */
function displayContinueButton($continueButton) {
	$continueButton.style.display = '';
}

/**
 * Hide Continue button
 * @param {Object} $continueButton Continue button js container
 */
function hideContinueButon($continueButton) {
	$continueButton.style.display = 'none';
}

/**
 * Return "true" if buyer is authenticated
 * Needed on the Checkout Page only
 * @param {Object} $payPalAccountList PayPal account list js container
 * @returns {boolean} "true" in case if buyer is authenticated
 */
function isBuyerAuthenticated($payPalAccountList) {
	return helper.tryParseJSON($payPalAccountList.dataset.customerAuthenticated);
}

/**
 * Select PayPal account tab
 * @param {Object} $payPalTab PayPal payment tab js container
 */
function selectPayPalCheckoutTab($payPalTab) {
	$payPalTab.click();
}

/**
 * Set email in to corresponding input
 * @param {Object} $braintreePaypalEmail BT PayPal email input js container
 * @param {string} email Email
 */
function setBraintreePayPalEmailInput($braintreePaypalEmail, email) {
	$braintreePaypalEmail.value = email;
}

/**
 * Returns whether the new PayPal account option is selected
 * @returns {boolean} Whether the new PayPal account option is selected
 */
function isNewPayPalAccountOptionSelected() {
	const $payPalAccountList = document.getElementById(
		'braintreePaypalAccountsList'
	);

	if ($payPalAccountList) {
		return $payPalAccountList.value === 'newaccount';
	}

	return false;
}

/**
 * Handles showing stored PP account in dropdown
 * Checks whether buyer has proceeded with the same email which is already stored
 * Triggered while tokenization callback and page reload
 * @param {boolean} buyerAuthenticated Whether customer is authenticated
 * @param {boolean} isSessionPaymentsEnabled Whether session card or not, depending on Session Payments Enabled pref
 */
function handleStoredAccountsShowing(
	buyerAuthenticated,
	isSessionPaymentsEnabled
) {
	if (!buyerAuthenticated) {
		return;
	}

	// Get stored account by session account email
	let $storedAccountOptionWithSameEmail;

	if (isSessionPaymentsEnabled) {
		$storedAccountOptionWithSameEmail =
			billingFormHelper.getStoredAccountBySesionAccountEmail();
	}

	// The buyer added an account with the same email address already in the buyer's wallet
	if ($storedAccountOptionWithSameEmail) {
		// Hide stored account with same email
		paypalCheckoutModelHelper.hideAccount($storedAccountOptionWithSameEmail);
	} else {
		// We need this to undo what was done by "if" statement
		paypalCheckoutModelHelper.displayAllStoredAccounts();
	}
}

module.exports = {
	isVaultModeEnabled,
	getAccountType,
	setNonceInputValue,
	hideSavePayPalAccountBlock,
	displaySavePayPalAccountBlock,
	displayPayPalButton,
	hidePayPalButon,
	displayContinueButton,
	hideContinueButon,
	isBuyerAuthenticated,
	selectPayPalCheckoutTab,
	setBraintreePayPalEmailInput,
	isNewPayPalAccountOptionSelected,
	handleStoredAccountsShowing,
};

'use strict';

const helper = require('../../helper');

const btPaypalAccontSelectorID = 'braintreePaypalAccount';

/**
 * Store payment as session payment
 * @param {string} nonce Nonce
 * @param {string} sessionAccountEmail Session account email
 */
function storePaymentAsSessionPayment(nonce, sessionAccountEmail) {
	const $accountListBlock = document.querySelector(
		'.js-braintree-used-paypal-account'
	);
	const $braintreePaypalNonceInput = document.querySelector(
		'input[name=braintreePaypalNonce]'
	);
	const $sessionPyapalAccount = document.getElementById(
		btPaypalAccontSelectorID
	);

	if ($braintreePaypalNonceInput) {
		$braintreePaypalNonceInput.value = nonce;
	}

	$sessionPyapalAccount.setAttribute('data-session-account', 'true');
	$sessionPyapalAccount.setAttribute('data-nonce', nonce);
	$sessionPyapalAccount.innerText = sessionAccountEmail;
	$sessionPyapalAccount.classList.remove('used-paypal-account-hide');

	// Show account list block
	$accountListBlock.classList.remove('used-paypal-account-hide');
}

/**
 * Select session paypal accounts.
 * Every time when buyer adds new account, the New Account option is selected (doesn't matter logged in buyer or not)
 */
function setSessionAccountOptionDefault() {
	const $savedPayPalAccountList = document.getElementById(
		'braintreePaypalAccountsList'
	);
	const $sessionPyapalAccount = document.getElementById(
		btPaypalAccontSelectorID
	);

	Array.prototype.forEach.call($savedPayPalAccountList, function (element) {
		element.removeAttribute('selected');
	});

	$sessionPyapalAccount.selected = true;
}

/**
 * Get stored account by session account email
 * @returns {Object} js container with stored account
 */
function getStoredAccountBySesionAccountEmail() {
	const sessionPaypalAccount = document.getElementById(
		'braintreePaypalAccount'
	);
	const paypalAccountsList = document.getElementById(
		'braintreePaypalAccountsList'
	);
	const sessionEmail = sessionPaypalAccount.value || null;

	return Array.apply(null, paypalAccountsList.options).find(function (el) {
		return (
			el.getAttribute('data-id') &&
			sessionEmail &&
			sessionEmail === el.innerText.trim()
		);
	});
}

/**
 * Fill DW billing form with billing address from Bt
 * @param {Object} braintreeAddress Billing address from BT
 * @param {Object} btDetails Details from BT
 * @returns {Object} Object with billing form fields and it's values
 */
function mapBraintreeWithDwBillingAddress(braintreeAddress, btDetails) {
	const $btPaymentMethodWrapper = document.querySelector(
		'.js-braintree-billing-payment-wrap'
	);
	const dwBillingFormFieldNames = helper.tryParseJSON(
		$btPaymentMethodWrapper.getAttribute('data-billing-form-fields-names')
	);

	dwBillingFormFieldNames.dwfrm_billing_addressFields_firstName =
		btDetails.firstName;
	dwBillingFormFieldNames.dwfrm_billing_addressFields_lastName =
		btDetails.lastName;
	dwBillingFormFieldNames.dwfrm_billing_addressFields_address1 =
		braintreeAddress.line1;
	dwBillingFormFieldNames.dwfrm_billing_addressFields_address2 =
		braintreeAddress.line2;
	dwBillingFormFieldNames.dwfrm_billing_addressFields_city =
		braintreeAddress.city;
	dwBillingFormFieldNames.dwfrm_billing_addressFields_postalCode =
		decodeURIComponent(braintreeAddress.postalCode);
	dwBillingFormFieldNames.dwfrm_billing_addressFields_states_stateCode =
		braintreeAddress.state;
	dwBillingFormFieldNames.dwfrm_billing_addressFields_country =
		braintreeAddress.countryCode;
	dwBillingFormFieldNames.dwfrm_billing_contactInfoFields_phone =
		btDetails.phone;

	return dwBillingFormFieldNames;
}

/**
 * Create billing address data
 * @param {Object} payloadDetails BT paymload details
 * @returns {Object} Mapped billing data
 */
function createBillingAddressData(payloadDetails) {
	const billingAddress = payloadDetails.billingAddress;

	billingAddress.firstName = payloadDetails.firstName;
	billingAddress.lastName = payloadDetails.lastName;
	billingAddress.email = payloadDetails.email;
	billingAddress.phone = payloadDetails.phone;
	billingAddress.countryCodeAlpha2 = billingAddress.countryCode;
	billingAddress.streetAddress = billingAddress.line1;
	billingAddress.extendedAddress = billingAddress.line2;
	billingAddress.locality = billingAddress.city;
	billingAddress.region = billingAddress.state;

	return billingAddress;
}

/**
 * Copy PayPal billing address to DW billing form
 * @param {Object} braintreeBillingAddress Billing address from BT
 * @param {Object} btDetails Details from bt
 */
function copyPayPalBillingAddressToDw(braintreeBillingAddress, btDetails) {
	const $braintreePaypalBillingAddressInput = document.querySelector(
		'input[name=braintreePaypalBillingAddress]'
	);
	const paypalBillingData = mapBraintreeWithDwBillingAddress(
		braintreeBillingAddress,
		btDetails
	);

	// Store billing address inside hidden input in order to send it to the server and
	// proccess with billing address server logic (the logic - billing address can't be overrided by
	// buyer. Only PayPal billing address should be used)
	$braintreePaypalBillingAddressInput.value = JSON.stringify(paypalBillingData);
	helper.updateBillingFormValues(paypalBillingData);
}

/**
 * Create billig address FormData
 * @param {Object} paypalBillingFormFields PayPal billing form fields
 * @param {Object} billingAddressData Billing address data
 * @returns {FormData} Billing FormData
 */
function createBillingAddressFormData(
	paypalBillingFormFields,
	billingAddressData
) {
	return helper.createPaymentFormData(paypalBillingFormFields, {
		firstName: billingAddressData.firstName,
		lastName: billingAddressData.lastName,
		address1: billingAddressData.streetAddress,
		address2: billingAddressData.extendedAddress || '',
		city: billingAddressData.locality,
		postalCode: decodeURIComponent(billingAddressData.postalCode),
		stateCode: billingAddressData.state,
		country: billingAddressData.countryCodeAlpha2,
		email: billingAddressData.email,
		phone: billingAddressData.phone,
		paymentMethod: 'PayPal',
	});
}

/**
 * Fill PayPal payment fields with payment data
 * @param {Object} btDetails Details from BT
 * @param {boolean} isSessionPaymentsEnabled Whether session card or not, depending on Session Payments Enabled pref
 */
function fillPayPalPaymentFields(btDetails, isSessionPaymentsEnabled) {
	const $braintreePaypalEmailInput = document.getElementById(
		'braintreePaypalEmail'
	);
	const $paypalAccountOption = document.getElementById(
		`${isSessionPaymentsEnabled ? btPaypalAccontSelectorID : 'newPPAccount'}`
	);
	const btEmail = btDetails.email;

	$braintreePaypalEmailInput.value = btEmail;
	$paypalAccountOption.value = btEmail;
	$paypalAccountOption.innerText = btEmail;

	// Hide Continue button
	if (window.braintreePreferences.isSessionPaymentsEnabled) {
		helper.continueButtonToggle(false);
	}
}

/**
 * Fill fraud data input with fraud data
 * @param {Object} data Fraud data
 */
function fillFraudDataInput(data) {
	const $braintreePaypalRiskDataInput = document.querySelector(
		'input[name=braintreePaypalRiskData]'
	);

	$braintreePaypalRiskDataInput.value = data.deviceData;
}

/**
 * Append CSRF token to the billing FormData
 * @param {FormData} formData Billing form data
 * @param {Object} $csrfToken CSRF token js container
 */
function appendCsrfTokenToFormData(formData, $csrfToken) {
	formData.append($csrfToken.name, $csrfToken.value);
}

/**
 * Append funding source to the billing FormData
 * @param {FormData} formData Billing form data
 * @param {string} fundingSource funding source
 */
function appendBraintreePaypalFundingSourceToFormData(formData, fundingSource) {
	formData.append('braintreePaypalFundingSource', fundingSource);
}

/**
 * Append email to the billing FormData
 * @param {FormData} formData billing form data
 * @param {string} email user email
 */
function appendEmailAsStringToFormData(formData, email) {
	formData.append('braintreePaypalEmail', email);
}

/**
 * Append fraud data to the FormData
 * @param {FormData} billingFromData billing FormData
 * @param {Object} fraudData fraud data
 */
function appendFraudDataToFormData(billingFromData, fraudData) {
	billingFromData.append('braintreePaypalRiskData', fraudData);
}

/**
 * Append billing address data as a string to the FromData
 * @param {FormData} billingFromData Billing FormData
 * @param {Object} billingData Billing data
 */
function appendBillingAddressAsStringToFormData(billingFromData, billingData) {
	billingFromData.append(
		'braintreePaypalBillingAddress',
		JSON.stringify(billingData)
	);
}

/**
 * Append BT payment fields to the FromData
 * @param {FormData} billingFromData Billing FormData
 * @param {Object} payload payload from BT
 */
function appendBtPaymentFieldsToFormData(billingFromData, payload) {
	billingFromData.append('braintreePaypalNonce', payload.nonce);
	billingFromData.append('braintreeSavePaypalAccount', false);
}

/**
 * Append params to the URL
 * @param {string} url To which append params
 * @param {Object} param Which should be appended to the URL
 * @returns {string} Final URL with passed params
 */
function appendToUrl(url, param) {
	let newUrl = url;

	newUrl +=
		(newUrl.indexOf('?') !== -1 ? '&' : '?') +
		Object.keys(param)
			.map(function (key) {
				return `${key}=${encodeURIComponent(param[key])}`;
			})
			.join('&');

	return newUrl;
}

module.exports = {
	storePaymentAsSessionPayment,
	setSessionAccountOptionDefault,
	getStoredAccountBySesionAccountEmail,
	copyPayPalBillingAddressToDw,
	createBillingAddressFormData,
	createBillingAddressData,
	fillPayPalPaymentFields,
	fillFraudDataInput,
	appendCsrfTokenToFormData,
	appendFraudDataToFormData,
	appendBillingAddressAsStringToFormData,
	appendBtPaymentFieldsToFormData,
	appendEmailAsStringToFormData,
	appendBraintreePaypalFundingSourceToFormData,
	// Utils
	appendToUrl,
};

'use strict';

const creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');

/**
 * Clears Credit Card fields of session account
 */
function clearCreditCardFields() {
	const creditCardFieldsArray = creditCardFormFieldHelper.getCCFields().asArray;

	creditCardFieldsArray.forEach(function (el) {
		el.value = '';
	});
}

/**
 * Clears Credit Card nonce of session account
 */
function clearCreditCardNonceField() {
	document.getElementById('braintreeCreditCardNonce').value = '';
}

module.exports = {
	clearCreditCardFields,
	clearCreditCardNonceField,
};

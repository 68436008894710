'use strict';

const payPalProductPage = require('./components/payPalProductPage');
const payPalProductViewPage = require('./components/payPalProductViewPage');
const payPalMiniCartPage = require('./components/payPalMiniCartPage');
const payPalCartPage = require('./components/payPalCartPage');
const payPalAccountPage = require('./components/payPalAccountPage');
const apmaPage = require('./components/apmaPage');
const payPalCheckoutPage = require('./components/payPalCheckoutPage');

let btClientInstancePromise;

/**
 * Init PayPal functionality on PDP
 */
function initProduct() {
	payPalProductPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on PVP
 */
function initQuickView() {
	payPalProductViewPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on MiniCart
 */
function initMiniCart() {
	// For MiniCart we generate client instance inside component.
	// This is, since general code which generate client instance executes before client token appear
	// This is only applicable for MiniCart
	payPalMiniCartPage.init();
}

/**
 * Init PayPal functionality on Cart
 */
function initCart() {
	payPalCartPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on Checkout
 */
function initCheckout() {
	payPalCheckoutPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on Account
 */
function initAccount() {
	payPalAccountPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on APMA page
 */
function initAPMA() {
	apmaPage.init(btClientInstancePromise);
}

/**
 * Init general variables which are used on all pages
 * @param {Object} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
	btClientInstancePromise = clientInstancePromise;
}

module.exports = {
	init,
	initCheckout,
	initProduct,
	initQuickView,
	initMiniCart,
	initCart,
	initAccount,
	initAPMA,
};

'use strict';

const braintreeSrcSDK = require('../braintreesdk/braintreeSrcSDK');
const braintreeGeneral = require('../../braintreeGeneral');
const helper = require('../../helper');

/**
 * SRC Base Model constructor
 * @param {Promise} clientInstancePromise client instance promise
 * @param {Object} srcButtonConfigurations SRC button configs
 * @param {Object} $braintreeSrcDeviceDataInput BT SRC device data input
 * @param {Object} loader loader instance
 * @param {Object} alertHandler Alert handler model instance
 */
function SrcBaseModel(
	clientInstancePromise,
	srcButtonConfigurations,
	$braintreeSrcDeviceDataInput,
	loader,
	alertHandler
) {
	this.clientInstancePromise = clientInstancePromise;
	this.srcButtonConfigurations = srcButtonConfigurations;
	this.$braintreeSrcDeviceDataInput = $braintreeSrcDeviceDataInput;
	this.loader = loader;
	this.alertHandler = alertHandler;

	this.braintreeSrcSdkButtonInit = braintreeSrcSDK.visaCheckoutInitialized;

	// Prop which will be settled after calling of "getBasketData" method
	this.basketData = {
		amount: 0,
	};
}

/**
 * Method is triggers when "initSrcButton" is called. Update total basket amount
 * @returns {Promise} Promise of get order data response
 */
SrcBaseModel.prototype.updateBasketData = function () {
	const SrcBaseModelInstance = this;

	return fetch(
		helper.getUrlWithCsrfToken(this.srcButtonConfigurations.getOrderInfoUrl)
	)
		.then((response) => response.json())
		.then((data) => {
			SrcBaseModelInstance.basketData.amount = data.amount;
		})
		.catch(() => window.location.reload());
};

/**
 * Retrun Init options which are used in SRC initialization
 * @returns {Object} object with Init options
 */
SrcBaseModel.prototype.getInitOptionsObject = function () {
	return {
		paymentRequest: {
			currencyCode: this.srcButtonConfigurations.options.currency || 'USD',
			subtotal: this.basketData.amount,
		},
		settings: {
			locale: this.srcButtonConfigurations.settings.locale,
			payment: {
				cardBrands: this.srcButtonConfigurations.settings.cardBrands,
				acceptCanadianVisaDebit:
					this.srcButtonConfigurations.settings.acceptCanadianVisaDebit,
			},
			shipping: {
				collectShipping:
					this.srcButtonConfigurations.options.isShippingAddressRequired,
			},
		},
	};
};

/**
 * Callback which is triggered when SRC approved payment on its side
 * This method will be overridden in scope of each child model
 * @param {Object} srcPayload payload from SRC
 */
// eslint-disable-next-line no-unused-vars
SrcBaseModel.prototype.onTokenizeCallback = function (srcPayload) {};

/**
 * Method add fraud data in to hidden input (if enabled)
 * @returns {Promise} with the result of operation
 */
SrcBaseModel.prototype.setFraudData = function () {
	// eslint-disable-next-line no-undef
	const SrcBaseModelInstance = this;
	const $braintreeSrcDeviceDataInput =
		SrcBaseModelInstance.$braintreeSrcDeviceDataInput;

	let result = Promise.resolve({});

	if (
		this.srcButtonConfigurations.isFraudToolsEnabled &&
		$braintreeSrcDeviceDataInput
	) {
		result = braintreeGeneral
			.collectFraudData(this.clientInstancePromise)
			.then(function (dataCollectorInstance) {
				SrcBaseModelInstance.$braintreeSrcDeviceDataInput.value =
					dataCollectorInstance.deviceData;
			});
	}

	return result;
};

/**
 * Method is called when we want to init SRC button
 * @returns {Promise} Promise with the result of the operation
 */
SrcBaseModel.prototype.initSrcButton = function () {
	const SrcBaseModelInstance = this;

	return SrcBaseModelInstance.updateBasketData()
		.then(function () {
			return SrcBaseModelInstance.setFraudData().then(function () {
				return SrcBaseModelInstance.braintreeSrcSdkButtonInit(
					SrcBaseModelInstance
				);
			});
		})
		.catch(function (error) {
			SrcBaseModelInstance.alertHandler.handleSrcError(error);
		});
};

module.exports = SrcBaseModel;

'use strict';

const braintreeGeneral = require('./braintreeGeneral');

// Components
const payPalStoredBillingAddressComponent = require('./paypal/components/payPalStoredBillingAddress');
const venmoBillingAddressComponent = require('./venmo/components/venmoBillingAddress');

// Helpers
const paymentMethodGeneralHelper = require('./paymentMethodGeneralHelper');
const helper = require('./helper');
const googlePayHelper = require('./googlepay/helpers/googlePayHelper');
const srcHelper = require('./src/helpers/srcHelper');
const venmoHelper = require('./venmo/helpers/venmoHelper');
const applePayHelper = require('./applepay/helpers/applePayHelper');
const payPalListHelper = require('./paypal/helpers/payPalListHelper');
const creditCardPaymentProcessingHelper = require('./creditcard/helpers/creditCardPaymentProcessingHelper');

// Constants used only for "createGeneralClientInstancePromise" function
const creditCardPaymentProcessingConstants = require('./creditcard/constants/creditCardPaymentProcessingConstants');

const CHECKOUT_PAGE = 'checkout_page';
const CART_MINICART_PRODUCT_PAGE = 'cart_minicart_product_page';
const LOCAL_PAYMENT_METHODS_LIST = [
	'sepa',
	'bancontact',
	'eps',
	'giropay',
	'ideal',
	'mybank',
	'p24',
	'sofort',
];
const PAYMENT_METHODS_WITH_DISABLED_BILLING_ADDRESS_FUNCTIONALITY = [
	'PayPal',
	'GooglePay',
	'SRC',
	'ApplePay',
	'Venmo',
];

// Other constants
const DATA_METHOD_ID_ATTRIBUTE = 'data-method-id';
const DATA_BILLING_ADDRESS_ATTRIBUTE = 'data-billing-address';
const PAYMENT_OPTIONS_CLASS = '.payment-options';

/**
 * Returns current page name
 * @returns {string} name of the current page
 */
function getApplicablePageType() {
	const isCheckoutPage = Boolean(document.getElementById('checkout-main'));
	// It is so, since MiniCart is exists on all mentioned pages
	const isCartMiniCartProductPage = Boolean(
		document.getElementsByClassName('minicart').length
	);

	let currentPageType;

	if (isCheckoutPage) {
		currentPageType = CHECKOUT_PAGE;
	} else if (isCartMiniCartProductPage) {
		currentPageType = CART_MINICART_PRODUCT_PAGE;
	}

	return currentPageType;
}

/**
 * Create Braintree Client Instance
 * @returns {Promise} with Braintree Client Instance
 */
function createGeneralClientInstancePromise() {
	const applycablePageType = getApplicablePageType();
	const attrBtClientToken = 'data-bt-client-token';

	let clientToken = null;
	let clientInstancePromise = null;
	let $billingElement;
	let $btExpressCheckoutPayPalButtonWrapper;
	let $btAccountPayPalButtonWrapper;
	let $btApmaPayPalButtonWrapper;

	// eslint-disable-next-line default-case
	switch (applycablePageType) {
		case CHECKOUT_PAGE:
			$billingElement = document.querySelector('.summary-details.billing');

			clientToken = $billingElement.getAttribute(attrBtClientToken);

			break;

		case CART_MINICART_PRODUCT_PAGE:
			$btExpressCheckoutPayPalButtonWrapper = document.querySelector(
				'.js-braintree-cart-paypal-buttons-wrap'
			);
			$btAccountPayPalButtonWrapper = document.querySelector(
				'.js-braintree-account-paypal-button-wrapper'
			);
			$btApmaPayPalButtonWrapper = document.querySelector(
				'.js-braintree-apma-paypal-button-wrapper'
			);

			if ($btExpressCheckoutPayPalButtonWrapper) {
				clientToken =
					$btExpressCheckoutPayPalButtonWrapper.getAttribute(attrBtClientToken);
			} else if ($btAccountPayPalButtonWrapper) {
				clientToken =
					$btAccountPayPalButtonWrapper.getAttribute(attrBtClientToken);
			} else if ($btApmaPayPalButtonWrapper) {
				clientToken =
					$btApmaPayPalButtonWrapper.getAttribute(attrBtClientToken);
			}

			break;
	}

	if (clientToken) {
		clientInstancePromise = braintreeGeneral.createClientInstance(clientToken);
	}

	return clientInstancePromise;
}

/**
 * Method override default SFRA payment-details filling behavior.
 * Method fill payment-details block with the right payment methdo data (like name, account, etc.)
 */
function fillPaymentSummaryContainer() {
	const $summaryDetails = document.querySelector(
		'.summary-details .payment-details'
	);

	if ($summaryDetails) {
		$summaryDetails.classList.add('js-braintree-payment-details');
		$summaryDetails.classList.remove('payment-details');

		$('body').on('checkout:updateCheckoutView', helper.updateCheckoutView);
	}
}

/**
 * May be used for all payment methods
 * Create error container and append it after PayPal button
 * @param {string} payPalButtonSelector selector of PayPal button
 * @returns {Object} return created error container
 */
function createErrorContainerAfterPayPalButton(payPalButtonSelector) {
	const $errorContainer = document.createElement('div');
	const $payPalButton = document.querySelector(payPalButtonSelector);

	$errorContainer.classList.add('alert-danger');
	$errorContainer.style.cssText = 'text-align: center';

	$payPalButton.parentNode.insertBefore(
		$errorContainer,
		$payPalButton.nextSibling
	);

	return $errorContainer;
}

/**
 * Initiates all necessary manipulation for credit card billing address due tab click
 *
 * @param {Element} paymentOption - payment option for which specific billing address needs to be selected
 */
function initCreditCardBillingAddressBehavior(paymentOption) {
	const creditCardHelper = require('./creditcard/helpers/creditCardHelper');
	const creditCardBillingAddressHelper = require('./creditcard/helpers/creditCardBillingAddressHelper');

	const $creditCardList = document.getElementById('braintreeCreditCardList');
	const $selectedCcAccountOption =
		creditCardHelper.getSelectedCcAccountOption();
	const creditCardFlow =
		creditCardPaymentProcessingHelper.getCreditCardFlowID($creditCardList);
	const savedBillingAddress = $selectedCcAccountOption.getAttribute(
		'data-billing-address'
	);

	if (
		creditCardFlow ===
			creditCardPaymentProcessingConstants.FLOW_STORED_CARD_NAME &&
		savedBillingAddress
	) {
		paymentMethodGeneralHelper.disableBillingAddressFunctionality();
		creditCardBillingAddressHelper.displaySelectedStoredBillingAddress(
			$selectedCcAccountOption
		);
	} else {
		const billingAddressSelector = document.getElementById(
			'billingAddressSelector'
		);
		const billingAddressId =
			paymentMethodGeneralHelper.getStoredBillingAddressId(paymentOption);

		if (billingAddressId) {
			const billingAddressToSelect = Array.from(
				billingAddressSelector.children
			).find((option) => {
				const stroredBillingAddress = option.getAttribute('value');

				return (
					stroredBillingAddress === billingAddressId ||
					stroredBillingAddress === `ab_${billingAddressId}` ||
					`ab_${stroredBillingAddress}` === billingAddressId
				);
			});

			if (billingAddressToSelect) {
				billingAddressToSelect.selected = true;
			}
		}

		paymentMethodGeneralHelper.enableBillingAddressFunctionality();
	}
}

/**
 * Deselects all selected options
 * @param {Element} billingAddressSelector selector of billing addresses
 */
function deselectAllOptions(billingAddressSelector) {
	Array.from(billingAddressSelector.children).forEach((option) => {
		option.selected = false;
		option.removeAttribute('selected');
	});
}

/**
 * Removes option that was added by payment method
 */
function removeAddedOption() {
	const optionToRemove = document.querySelector('.stored-billing-address');

	if (optionToRemove) {
		optionToRemove.remove();
	}
}

/**
 * Selects billing address
 *
 * @param {Element} paymentOption payment option for which specific billing address needs to be selected
 */
function selectBillingAddress(paymentOption) {
	const selectedMethodID = paymentOption.getAttribute(DATA_METHOD_ID_ATTRIBUTE);
	const optionClass = 'stored-billing-address';

	switch (selectedMethodID) {
		case window.braintreeConstants.PAYMENT_METHOD_ID_PAYPAL: {
			const payPalAccountList = document.getElementById(
				'braintreePaypalAccountsList'
			);
			const selectedPayPalAccountOption =
				payPalAccountList.options[payPalAccountList.selectedIndex];

			// Display billing address of selected PayPal account
			payPalStoredBillingAddressComponent.displaySelectedStoredBillingAddress(
				selectedPayPalAccountOption
			);

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_GOOGLEPAY: {
			const googlePayBtn = document.querySelector(
				'.js-braintree-googlepay-button'
			);

			paymentMethodGeneralHelper.createBillingAddressOption(
				optionClass,
				googlePayBtn.getAttribute(DATA_BILLING_ADDRESS_ATTRIBUTE)
			);

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_SRC: {
			const srcBtn = document.querySelector('.js-braintree-src-button');

			paymentMethodGeneralHelper.createBillingAddressOption(
				optionClass,
				srcBtn.getAttribute(DATA_BILLING_ADDRESS_ATTRIBUTE)
			);

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_APPLEPAY: {
			const applePayBtn = document.querySelector(
				'.js-braintree-applepay-button'
			);

			paymentMethodGeneralHelper.createBillingAddressOption(
				optionClass,
				applePayBtn.getAttribute(DATA_BILLING_ADDRESS_ATTRIBUTE)
			);

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_VENMO: {
			// Display shipping address as selected billing address
			venmoBillingAddressComponent.displayShippingAddressAsSelected();

			break;
		}

		default:
	}
}

/**
 * Handles billing address functionality (disable/enable)
 * @param {Element} paymentOption payment option
 */
function handleBillingAddressFunctionality(paymentOption) {
	const selectedMethodID = paymentOption.getAttribute(DATA_METHOD_ID_ATTRIBUTE);

	if (
		PAYMENT_METHODS_WITH_DISABLED_BILLING_ADDRESS_FUNCTIONALITY.includes(
			selectedMethodID
		)
	) {
		paymentMethodGeneralHelper.disableBillingAddressFunctionality();
	} else if (selectedMethodID === 'CREDIT_CARD') {
		initCreditCardBillingAddressBehavior(paymentOption);
	} else {
		paymentMethodGeneralHelper.enableBillingAddressFunctionality();
	}
}

/**
 * Handles submit payment button for specific payment method
 * @param {Element} paymentOption payment option
 */
function handlePaymentMethodsFunctionality(paymentOption) {
	let selectedMethodID = paymentOption.getAttribute(DATA_METHOD_ID_ATTRIBUTE);

	if (LOCAL_PAYMENT_METHODS_LIST.includes(selectedMethodID)) {
		selectedMethodID = 'LPM';
	}

	switch (selectedMethodID) {
		case window.braintreeConstants.PAYMENT_METHOD_ID_PAYPAL: {
			// Submit Payment button behavior
			const isAccountListHidden = document
				.querySelector('.js-braintree-used-paypal-account')
				.classList.contains('used-paypal-account-hide');

			if (isAccountListHidden) {
				paymentMethodGeneralHelper.hideSubmitPaymentButton();
			} else if (!payPalListHelper.isNewPayPalAccountOptionSelected()) {
				paymentMethodGeneralHelper.showSubmitPaymentButton();
			} else {
				paymentMethodGeneralHelper.hideSubmitPaymentButton();
			}

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_GOOGLEPAY: {
			googlePayHelper.googlePayContentTabHandler(true);

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_SRC: {
			srcHelper.srcContentTabHandler(true);

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_APPLEPAY: {
			applePayHelper.toggleContinueButtonOnBillingPage();

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_CREDIT_CARD: {
			// Submit Payment button behavior
			paymentMethodGeneralHelper.showSubmitPaymentButton();

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_VENMO: {
			const isBtVenmoAccountsList = Boolean(
				document.getElementById('braintreeVenmoAccountsList')
			);
			const isSessionAccountExist = Boolean(
				document.querySelector('.js-used-venmo-account')
			);
			// Handles continue button visibility on the Billing Page in Venmo tab
			// Case for the registered user with saved account

			if (isBtVenmoAccountsList) {
				venmoHelper.venmoContentTabHandler();
			} else if (isSessionAccountExist) {
				// Case when only session account exist without saved account
				helper.continueButtonToggle(false);
			} else {
				// Case when no session/saved account exist and Venmo button is shown
				helper.continueButtonToggle(true);
			}

			break;
		}

		case window.braintreeConstants.PAYMENT_METHOD_ID_LPM: {
			paymentMethodGeneralHelper.hideSubmitPaymentButton();

			break;
		}

		case 'GIFT_CERTIFICATE': {
			paymentMethodGeneralHelper.hideSubmitPaymentButton();

			break;
		}

		default:
	}
}

/**
 * Saves selected billing address on tab changing
 */
function saveSelectedBillingAddressFromPreviousTab() {
	const billingAddressSelector = document.getElementById(
		'billingAddressSelector'
	);
	const paymentOptions = document.querySelector(PAYMENT_OPTIONS_CLASS);

	const paymentOptionToSaveBillingAddress = Array.from(
		paymentOptions.children
	).find((option) => {
		return option.firstElementChild.classList.contains('active');
	});

	const selectedMethodID = paymentOptionToSaveBillingAddress.getAttribute(
		DATA_METHOD_ID_ATTRIBUTE
	);

	if (
		!PAYMENT_METHODS_WITH_DISABLED_BILLING_ADDRESS_FUNCTIONALITY.includes(
			selectedMethodID
		)
	) {
		const selectedBillingAddressOption =
			billingAddressSelector.options[billingAddressSelector.selectedIndex];

		paymentMethodGeneralHelper.setStoredBillingAddressId(
			paymentOptionToSaveBillingAddress,
			selectedBillingAddressOption
		);
	}
}

/**
 * Inits billing address behavior on shipping change, so that:
 * When the checkout stage changes to payment, trigger a custom event that will select the billing
 * address based on the active payment option.
 */
function initChangeShippingBehavior() {
	const checkoutStageElement = document.querySelector('.data-checkout-stage');

	const observer = new MutationObserver(() => {
		if (
			checkoutStageElement.getAttribute('data-checkout-stage') === 'payment'
		) {
			const shippingChangeEvent = new Event('shipping:update');

			document.dispatchEvent(shippingChangeEvent);
		}
	});

	observer.observe(checkoutStageElement, { attributes: true });

	document.addEventListener('shipping:update', () => {
		const paymentOptions = document.querySelector(PAYMENT_OPTIONS_CLASS);
		const billingAddressSelector = document.getElementById(
			'billingAddressSelector'
		);

		if (paymentOptions && billingAddressSelector) {
			const activePaymentOption = Array.from(paymentOptions.children).find(
				(option) => {
					return option.firstElementChild.classList.contains('active');
				}
			);

			selectBillingAddress(activePaymentOption);
			handleBillingAddressFunctionality(activePaymentOption);
		}
	});
}

/**
 * Inits functionality of billing selector change
 */
function initBillingSelectorChangeFunctionality() {
	const billingAddressSelector = document.getElementById(
		'billingAddressSelector'
	);
	const paymentOptions = document.querySelector(PAYMENT_OPTIONS_CLASS);

	if (billingAddressSelector && paymentOptions) {
		billingAddressSelector.addEventListener('change', () => {
			const selectedBillingAddressOption =
				billingAddressSelector.options[billingAddressSelector.selectedIndex];
			const activePaymentOption = Array.from(paymentOptions.children).find(
				(option) => {
					return option.firstElementChild.classList.contains('active');
				}
			);

			paymentMethodGeneralHelper.setStoredBillingAddressId(
				activePaymentOption,
				selectedBillingAddressOption
			);
		});
	}
}

/**
 * Init behavior which should be triggered when buyer clicked on Payment Method tab.
 * Currently we handle billing address modification enabling/disabling.
 * We also have init behavior of "Submit Payment" button
 */
function initPaymentMethodTabClickBehavior() {
	const navLinks = document.querySelectorAll(
		'.payment-options .nav-item .nav-link'
	);

	navLinks.forEach((navLink) => {
		navLink.addEventListener('click', (e) => {
			const clickedPaymentOption = e.target.closest('.nav-item');
			const billingAddressSelector = document.getElementById(
				'billingAddressSelector'
			);

			saveSelectedBillingAddressFromPreviousTab();

			removeAddedOption();
			deselectAllOptions(billingAddressSelector);

			handleBillingAddressFunctionality(clickedPaymentOption);
			handlePaymentMethodsFunctionality(clickedPaymentOption);

			selectBillingAddress(clickedPaymentOption);
		});
	});
}

/**
 * Creates customer form with email to submit it to CheckoutServices-SubmitCustomer endpoint
 * @param {string} email email from payment method's response
 * @returns {Object} customer form data
 */
function createCustomerFormData(email) {
	const data = {
		email: email,
	};

	const $csrfToken = document.querySelector(
		'.js-braintree-cart-buttons-wrap #csrf_token'
	);
	const checkoutCustomerFormFields = document
		.querySelector('.js-braintree-cart-buttons-wrap')
		.getAttribute('data-checkout-customer-form-fields');
	const customerFormData = helper.createPaymentFormData(
		checkoutCustomerFormFields,
		data
	);

	customerFormData.append($csrfToken.name, $csrfToken.value);

	return customerFormData;
}

/**
 * Submits customer form with email to CheckoutServices-SubmitCustomer endpoint
 * Call is triggered only in case if email in basket is empty (guest first checkout from cart)
 * @param {string} email email from payment method's response
 * @returns {Promise} call to CheckoutServices-SubmitCustomer endpoint
 */
async function submitCustomerForm(email) {
	const buttonsWrapper = document.querySelector(
		'.js-braintree-cart-buttons-wrap'
	);
	const isCustomerEmailEmpty = helper.tryParseJSON(
		buttonsWrapper.getAttribute('data-is-customer-email-empty')
	);

	let result;
	// Could be "true" only in case if email in basket is empty (first guest checkout from cart)

	if (isCustomerEmailEmpty) {
		const submitCustomerUrl = window.braintreeUrls.submitCustomerUrl;
		const customerFormData = createCustomerFormData(email);

		result = await fetch(submitCustomerUrl, {
			method: 'POST',
			body: customerFormData,
		});
	}

	return result;
}

/**
 * Handles active CC session payment, removes active session payment if order amount changed
 * to avoid a mismatch error between nonce amount and actual amount
 * @param {number} lastAmount - amount of the last order
 */
function handleActiveCCSessionPayment(lastAmount) {
	fetch(helper.getUrlWithCsrfToken(window.braintreeUrls.getOrderInfoUrl))
		.then((response) => response.json())
		.then((data) => {
			if (data.amount !== lastAmount) {
				const creditCardSessionAccount = require('./creditcard/components/creditCardSessionAccount');

				creditCardSessionAccount.removeSessionNonce();
			}
		});
}

/**
 * Inits payment amount observer
 */
function initAmountObserver() {
	const checkoutStageElement = document.querySelector('.data-checkout-stage');

	const observer = new MutationObserver(() => {
		if (
			checkoutStageElement.getAttribute('data-checkout-stage') === 'placeOrder'
		) {
			const amountElement = document.querySelector('.grand-total-sum');
			const amount = parseFloat(
				amountElement.textContent.replace(/[^0-9.]/g, '')
			);

			window.sessionStorage.setItem('amount-used', amount);
		}
	});

	observer.observe(checkoutStageElement, { attributes: true });
}

module.exports = {
	createGeneralClientInstancePromise,
	fillPaymentSummaryContainer,
	createErrorContainerAfterPayPalButton,
	initPaymentMethodTabClickBehavior,
	initChangeShippingBehavior,
	initBillingSelectorChangeFunctionality,
	submitCustomerForm,
	saveSelectedBillingAddressFromPreviousTab,
	handleActiveCCSessionPayment,
	initAmountObserver,
};

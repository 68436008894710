'use strict';

const helper = require('./helper');

function hideBillingAddressManipulationButtons() {
	document.querySelector(
		'.address-selector-block .btn-show-details'
	).style.display = 'none';
	document.querySelector('.address-selector-block .btn-add-new').style.display =
		'none';
}

function showBillingAddressManipulationButtons() {
	document.querySelector(
		'.address-selector-block .btn-show-details'
	).style.display = '';
	document.querySelector('.address-selector-block .btn-add-new').style.display =
		'';
}

function disableBillingAddressList() {
	document
		.getElementById('billingAddressSelector')
		.setAttribute('disabled', 'disabled');
}

function enableBillingAddressList() {
	document.getElementById('billingAddressSelector').removeAttribute('disabled');
}

function hideSubmitPaymentButton() {
	document.querySelector('.submit-payment').style.display = 'none';
	document.querySelector('.submit-payment-new').style.display = 'none';
}

function showSubmitPaymentButton() {
	document.querySelector('.submit-payment').style.display = '';
	document.querySelector('.submit-payment-new').style.display = '';
}

/**
 * Hides biiling address form on the Billing Page for appropriate tabs.
 * Case when customer clicked 'Updated address' or 'Add New' button and flipped through the payment method tabs
 */
function hideBillingAddressForm() {
	document.querySelector(
		'.billing-address-block .billing-address'
	).style.display = 'none';
}

/**
 * Shows biiling address form on the Billing Page for appropriate tabs.
 * Case when customer clicked 'Updated address' or 'Add New' button and flipped through the payment method tabs
 */
function showBillingAddressForm() {
	document.querySelector(
		'.billing-address-block .billing-address'
	).style.display = '';
}

/**
 * Hides phone field on the Billing Page for appropriate tabs
 */
function hidePhoneField() {
	document.querySelector(
		'.dwfrm_billing_contactInfoFields_phone'
	).style.display = 'none';
}

/**
 * Shows phone field on the Billing Page for appropriate tabs
 */
function showPhoneField() {
	document.querySelector(
		'.dwfrm_billing_contactInfoFields_phone'
	).style.display = '';
}

/**
 * Disabled billing address functionality on the Billing Page
 */
function disableBillingAddressFunctionality() {
	disableBillingAddressList();
	hideBillingAddressManipulationButtons();
	hideBillingAddressForm();
	hidePhoneField();
}

/**
 * Enabled billing address functionality on the Billing Page
 */
function enableBillingAddressFunctionality() {
	showBillingAddressManipulationButtons();
	enableBillingAddressList();
	showBillingAddressForm();
	showPhoneField();
}

/**
 * Returns a billing address represented as a string
 * @param {Object} billingAddress A billing address object
 * @returns {string} A string represented a billing address
 */
function formatBaAsString(billingAddress) {
	const firstName = billingAddress.firstName || '';
	const lastname = billingAddress.lastName || '';
	const address1 =
		billingAddress.line1 ||
		billingAddress.address1 ||
		billingAddress.streetAddress ||
		'';
	const city = billingAddress.city || billingAddress.locality || '';
	const state =
		billingAddress.state ||
		billingAddress.stateCode ||
		billingAddress.region ||
		'';
	const postalCode = decodeURIComponent(billingAddress.postalCode) || '';

	return `${firstName} ${lastname} ${address1} ${city}, ${state} ${postalCode}`;
}

/**
 * Extract billing address of corresponding payment account from account option
 * @param {Object} $selectedPayment PayPal payment option
 * @returns {string} return string representation of extracted address
 */
function getBillingAddressAsString($selectedPayment) {
	const selectedBillingAddressStr = $selectedPayment.getAttribute(
		'data-billing-address'
	);

	let billingAddressRepresentationString = '';

	if (selectedBillingAddressStr) {
		const selectedBillingAddressObj = helper.tryParseJSON(
			selectedBillingAddressStr
		);

		if (selectedBillingAddressObj && selectedBillingAddressStr !== '{}') {
			billingAddressRepresentationString = formatBaAsString(
				selectedBillingAddressObj
			);
		}
	}

	return billingAddressRepresentationString;
}

/**
 * It gets the ID of the last billing address used for the selected payment method
 * @param {Element} paymentOption - The payment option that was selected.
 * @returns {string} - The billing address ID that was stored in session storage.
 */
function getStoredBillingAddressId(paymentOption) {
	const selectedPaymentMethod = paymentOption.getAttribute('data-method-id');

	return sessionStorage.getItem(`${selectedPaymentMethod}-last-billing-id`);
}

/**
 * It sets the billing address ID in session storage for the selected payment method
 * @param {Element} paymentOption - The payment method that was selected.
 * @param {Element} billingOption - The billing address option that was selected.
 */
function setStoredBillingAddressId(paymentOption, billingOption) {
	const selectedPaymentMethodName =
		paymentOption.getAttribute('data-method-id');

	if (billingOption.getAttribute('value') !== null) {
		sessionStorage.setItem(
			`${selectedPaymentMethodName}-last-billing-id`,
			billingOption.getAttribute('value')
		);
	}
}

/**
 * It creates an option element, sets its text, class, and selected property, and then appends it to
 * the billingAddressSelector element
 * @param {string} className - Class name of the option element.
 * @param {string} innerText - Text that will be displayed in the dropdown menu.
 */
function createBillingAddressOption(className, innerText) {
	const billingAddressSelector = document.getElementById(
		'billingAddressSelector'
	);
	const option = document.createElement('option');

	option.innerText = innerText;
	option.className = className;
	option.selected = true;

	billingAddressSelector.append(option);
}

module.exports = {
	disableBillingAddressFunctionality,
	enableBillingAddressFunctionality,
	hideBillingAddressManipulationButtons,
	showBillingAddressManipulationButtons,
	disableBillingAddressList,
	enableBillingAddressList,
	hideBillingAddressForm,
	showBillingAddressForm,
	hideSubmitPaymentButton,
	showSubmitPaymentButton,
	hidePhoneField,
	showPhoneField,
	getStoredBillingAddressId,
	setStoredBillingAddressId,
	createBillingAddressOption,
	getBillingAddressAsString,
};

'use strict';

// Helpers
const googlePayHelper = require('../helpers/googlePayHelper');
const loaderInstance = require('../../loaderHelper');
const googlePayPaymentProcessingHelper = require('../helpers/googlePayPaymentProcessingHelper');
const helper = require('../../helper');

// Components
const googlePayCart = require('../components/googlePayCart');
const googlePayCheckout = require('../components/googlePayCheckout');
const googlePaySessionAccount = require('../components/googlePaySessionAccount');
const googlePayPaymentProcessingConstants = require('../constants/googlePayPaymentProcessingConstants');
const { data, error } = require('jquery');

// Global varibales
let googlePayModel;
let paymentsClient;
let googlePayConfig;
let alertHandler;

const $googlePayButton = document.querySelector(
	'.js-braintree-googlepay-button'
);
const $braintreeGooglePayLoader = document.querySelector(
	'.js-braintree-googlepay-loader'
);
const $continueButton = document.querySelector('button.submit-payment');

let loader;

/**
 * Functionality over tokenized payment
 * @param {Objet} data data object
 * @param {Object} result data object
 */
function onTokenizePayment(data, result) {
	// General functionality for Cart and Billing Page
	const googlePayNonce = result.nonce;
	const googlePayPaymentType = result.type;
	const gpCardDescription = `${result.details.cardType} ${result.details.lastFour}`;
	const billingAddressData =
		googlePayPaymentProcessingHelper.createGooglepayBillingAddressData(data);
	const googlePayFlow = googlePayPaymentProcessingHelper.getGooglePayFlowId();

	let shippingAddressData;

	helper.removeActiveSessionPayment();

	switch (googlePayFlow) {
		case googlePayPaymentProcessingConstants.FLOW_CART_NAME:
			shippingAddressData =
				googlePayPaymentProcessingHelper.createGooglepayShippingAddressData(
					data.shippingAddress
				);

			googlePayCart.init(
				billingAddressData,
				shippingAddressData,
				googlePayNonce,
				googlePayPaymentType,
				gpCardDescription,
				alertHandler
			);

			break;
		case googlePayPaymentProcessingConstants.FLOW_CHECKOUT_NAME:
			const isSessionPaymentsEnabled =
				window.braintreePreferences.isSessionPaymentsEnabled;

			googlePayCheckout.init(
				googlePayNonce,
				gpCardDescription,
				googlePayPaymentType,
				billingAddressData
			);

			if (isSessionPaymentsEnabled) {
				googlePaySessionAccount.showGooglepayAccount(gpCardDescription);
			}

			$continueButton.click();

			break;
		default:
			break;
	}
}

/**
 * Call back function to be resolved when on Google Pay payment button is clicked
 * @returns {void}
 */
async function onGooglePaymentButtonClicked() {
	// eslint-disable-line no-inner-declarations
	const options = googlePayConfig.options;
	const amount = options.amount;

	if (googlePayConfig.googleMerchantId) {
		options.merchantId = googlePayConfig.googleMerchantId;
	}

	alertHandler.hideAlerts();

	try {
		if (amount === 0) {
			alertHandler.showOrderTotalError();
		}

		loader.show();

		// Updates order amount for paymentDataRequest
		await googlePayModel.googlePayUpdateAmount();

		if (options.isShippingOptionRequired) {
			const paymentMethodId =
				window.braintreeConstants.PAYMENT_METHOD_ID_GOOGLEPAY;
			const url = `${window.braintreeUrls.getApplicableShippingOptions}?paymentMethodId=${paymentMethodId}`;
			const data = await helper.getApplicableShippingOptions(url);
			options.amount = data.amount;
			options.shippingOptions = data.shippingOptions;

			if ('errorMessages' in data && data.errorMessages !== '') {
				loader.hide();
				throw error(data.errorMessages);
			}
		}

		// Updates order amount for paymentDataRequest
		await googlePayModel.googlePayUpdateAmount();

		// Create a configuration object for use in the loadPaymentData method.
		const paymentDataRequest = googlePayModel.createPaymentDataRequest(options);

		// Update card payment methods to require billing address
		const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];

		cardPaymentMethod.parameters.billingAddressRequired = true;
		cardPaymentMethod.parameters.billingAddressParameters = {
			format: 'FULL',
			phoneNumberRequired: true,
		};

		paymentsClient
			.loadPaymentData(paymentDataRequest)
			.then(function (paymentData) {
				googlePayModel
					.parseResponse(paymentData)
					.then(function (tokenizePayload) {
						onTokenizePayment(paymentData, tokenizePayload);
					});
				loader.hide();
			})
			.catch(function (error) {
				loader.hide();
				alertHandler.handleGooglePayError(error);
			});
	} catch (error) {
		alertHandler.handleGooglePayError(error);
	}
}

/**
 * Init and add Google Pay on the Cart or Billing Page
 */
function initGooglePay() {
	// This class generated by the Google Pay API, if size mode in BM is set to fill
	const $googlePayButtonFillStyle = document.querySelector(
		'.gpay-button-fill-new-style'
	);

	if (!$googlePayButtonFillStyle) {
		$googlePayButton.classList.add('googlepay-container');
	}

	// added default Google Pay style configuration in case of an error
	let googlePayButtonStyle = {
		buttonColor: 'black',
		buttonType: 'buy',
		buttonSizeMode: 'static',
	};

	const googlePayButtonConfigs = googlePayHelper.getBraintreeGooglePayConfigs();

	if (googlePayButtonConfigs && googlePayButtonConfigs.style) {
		googlePayButtonStyle = googlePayButtonConfigs.style;
	}

	const result = googlePayModel
		.collectFraudData()
		.then(function (fraudDataPayload) {
			googlePayHelper.setGooglePayDeviceData(fraudDataPayload);
			loader.hide();

			return googlePayModel
				.initGooglePay(googlePayConfig.googleMerchantId)
				.then(function (response) {
					if (response.result) {
						// Creates 'Google Pay Button' and append to the DOM
						googlePayButtonStyle.onClick = onGooglePaymentButtonClicked;
						const button = paymentsClient.createButton(googlePayButtonStyle);

						$googlePayButton.appendChild(button);
					}
				});
		});

	result.catch(function (error) {
		loader.hide();
		alertHandler.handleGooglePayError(error);
	});
}

/**
 * Inits Google Pay payment processing components
 * @param {Object} braintreeGooglePayModel Google Pay model
 * @param {Constructor} alertHandlerModel Alert handler model
 */
function init(braintreeGooglePayModel, alertHandlerModel) {
	loader = loaderInstance($braintreeGooglePayLoader);
	googlePayModel = braintreeGooglePayModel;
	paymentsClient = braintreeGooglePayModel.getPaymentsClient();
	googlePayConfig = helper.tryParseJSON(
		$googlePayButton.getAttribute('data-braintree-config')
	);
	alertHandler = alertHandlerModel;

	initGooglePay();
}

module.exports = {
	init,
};

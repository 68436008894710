'use strict';

const helper = require('../../helper');

/**
 * Returns Braintree Google Pay configs
 * @returns {Object} Braintree Google Pay configs
 */
function getBraintreeGooglePayConfigs() {
	let braintreeGooglePayButtonConfigs = null;

	try {
		braintreeGooglePayButtonConfigs = helper.tryParseJSON(
			document
				.querySelector('.js-braintree-googlepay-button')
				.getAttribute('data-braintree-config')
		);
	} catch (error) {
		return braintreeGooglePayButtonConfigs;
	}

	return braintreeGooglePayButtonConfigs;
}

/**
 * Sets device data from 'data collector' to corresponding input field
 * @param {Object} fraudDataPayload Froud payload data
 */
function setGooglePayDeviceData(fraudDataPayload) {
	const $braintreeGooglePayDeviceData = document.getElementById(
		'braintreeGooglePayDeviceData'
	);

	if (fraudDataPayload.deviceData) {
		$braintreeGooglePayDeviceData.value = fraudDataPayload.deviceData;
	}
}

/**
 * Handles all behavior related to the Google Pay content tab
 * @param {boolean} isActiveGooglePayTab Whether Google Pay tab is active
 */
function googlePayContentTabHandler(isActiveGooglePayTab) {
	const $btGooglepayAccountsList = document.getElementById(
		'braintreeGooglepayAccountsList'
	);
	const $googlepayButton = document.querySelector(
		'.js-braintree-googlepay-button'
	);
	const $googlepayContent = document.querySelector(
		'.js-braintree-googlepay-content'
	);

	if ($btGooglepayAccountsList.value === 'newaccount') {
		$googlepayButton.classList.remove('d-none');

		if (isActiveGooglePayTab) {
			helper.continueButtonToggle(true);
		}

		$googlepayContent.setAttribute('data-paypal-is-hide-continue-button', true);
	} else {
		$googlepayButton.classList.add('d-none');
		helper.continueButtonToggle(false);
		$googlepayContent.setAttribute(
			'data-paypal-is-hide-continue-button',
			false
		);
	}
}

/**
 * Triggers the Google Pay tab if Google Pay session account uses
 * @param {Object} googlePayConfigs Google Pay config object
 */
function triggerGooglePayTab(googlePayConfigs) {
	const sessionPaymentMethodId = googlePayConfigs.sessionPaymentMethodId;
	const $googlePayTab = document.querySelector('.js-googlepay-tab');

	if (sessionPaymentMethodId === 'GooglePay') {
		$googlePayTab.click();
	}
}

module.exports = {
	getBraintreeGooglePayConfigs,
	setGooglePayDeviceData,
	googlePayContentTabHandler,
	triggerGooglePayTab,
};

'use strict';

// This file is about form fields manipulations.

/**
 * Returns an object contains an object and an array of credit card fields to Display
 * @returns {Object} Object
 */
function getCCFieldsToDisplay() {
	const ccToDisplayFieldsAsObject = {
		cardOwnerToDisplay: document.getElementById('braintreeCardOwnerToDisplay'),
		cardNumberToDisplay: document.getElementById(
			'braintreeCardNumberToDisplay'
		),
		cardExpirationToDisplay: document.getElementById(
			'braintreeExpirationToDisplay'
		),
	};

	return {
		asObject: ccToDisplayFieldsAsObject,
		asArray: Object.values(ccToDisplayFieldsAsObject),
	};
}

/**
 * Returns an object contains an object and an array of credit card cvv field to Display
 * @returns {Object} Object
 */
const getCcCvvToDisplayField = () => {
	const asObject = {
		cardCvvToDisplay: document.getElementById('braintreeCvvToDisplay'),
	};

	return {
		asObject: asObject,
		asArray: Object.values(asObject),
	};
};

/**
 * Returns an object contains an object and an array of cvv credit card field
 * @returns {Object} Object
 */
const getCcCvvField = () => {
	const asObject = {
		braintreeCvv: document.getElementById('braintreeCvv'),
	};

	return {
		asObject: asObject,
		asArray: Object.values(asObject),
	};
};

/**
 * Returns an object contains an object and an array of credit card fields
 * @returns {Object} Object
 */
function getCCFields() {
	const ccFieldsAsObject = {
		braintreeCardOwner: document.getElementById('braintreeCardOwner'),
		braintreeExpirationDate: document.getElementById('braintreeExpirationDate'),
		braintreeCardNumber: document.getElementById('braintreeCardNumber'),
	};

	return {
		asObject: ccFieldsAsObject,
		asArray: Object.values(ccFieldsAsObject),
	};
}

/**
 * Returns braintreeCreditCardNonce container
 * @returns {Object} Input element
 */
function getCreditCardNonceFieldContainer() {
	return document.getElementById('braintreeCreditCardNonce');
}

/**
 * Returns braintreeCreditCardList container
 * @returns {Object} Select element
 */
function getCreditCardListContainer() {
	return document.getElementById('braintreeCreditCardList');
}

/**
 * Sets Credit Card field to display
 * @param {Object} selectedCard The values of fields to be set
 * @param {Object} cacheCardFields Object of elements contain credit cards fields to display
 */
function setCreditCardFieldsToDisplay(selectedCard, cacheCardFields) {
	const cartNumber = selectedCard.dataset.number;

	cacheCardFields.cardNumberToDisplay.innerHTML = cartNumber;
	cacheCardFields.cardExpirationToDisplay.innerHTML =
		selectedCard.dataset.expiration;
	cacheCardFields.cardOwnerToDisplay.innerHTML = selectedCard.dataset.owner;

	if (cacheCardFields.cardCvvToDisplay) {
		cacheCardFields.cardCvvToDisplay.innerHTML = '***';
	}

	document.getElementById('braintreeCardType').value =
		selectedCard.dataset.type;
	document.getElementById('braintreeCardMaskNumber').value = cartNumber;
}

/**
 * Sets Credit Card fileds on the Billing Page
 * @param {Object} selectedCard The values of fields to be set
 * @param {Object} cacheCardFields Object of elements contain credit cards fields
 */
function setCardFields(selectedCard, cacheCardFields) {
	cacheCardFields.cardNumberToDisplay.innerHTML =
		selectedCard['data-number'].value;
	cacheCardFields.cardExpirationToDisplay.innerHTML =
		selectedCard['data-expiration'].value;
	cacheCardFields.cardOwnerToDisplay.innerHTML =
		selectedCard['data-owner'].value;

	if (cacheCardFields.cardCvvToDisplay) {
		cacheCardFields.cardCvvToDisplay.innerHTML = '***';
	}

	document.getElementById('braintreeCardType').value =
		selectedCard['data-type'].value;
	document.getElementById('braintreeCardMaskNumber').value =
		selectedCard['data-number'].value;
}

/**
 * Shows Credit Card fileds on the Billing Page
 * @param {Array} cardFields Array of credit card fileds
 */
function showCardElements(cardFields) {
	cardFields.forEach(function (el) {
		el.style.display = '';
	});
}

/**
 * Hides Credit Card fileds on the Billing Page
 * @param {Array} cardFields Array of Credit Card fileds
 */
function hideCardElements(cardFields) {
	cardFields.forEach(function (el) {
		el.style.display = 'none';
	});
}

/**
 * Returns Credit Card hosted fileds styles
 * @returns {Object} Object of styles
 */
function getHostedFieldsStyles() {
	return {
		input: {
			'font-size': '12px',
			color: '#b7802a',
		},
		':focus': {
			color: 'blue',
		},
		'.valid': {
			color: 'green',
		},
		'.invalid': {
			color: 'red',
		},
		'.cvv::placeholder': {
			color: 'red',
		},
	};
}

module.exports = {
	getCCFieldsToDisplay,
	getCCFields,
	getCreditCardNonceFieldContainer,
	getCreditCardListContainer,
	showCardElements,
	hideCardElements,
	setCreditCardFieldsToDisplay,
	setCardFields,
	getHostedFieldsStyles,
	getCcCvvToDisplayField,
	getCcCvvField,
};

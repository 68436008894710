'use strict';

/* global Promise $ */

const braintreePayPalSDK = require('../braintreesdk/braintreePayPalSDK');
const braintreeGeneral = require('../../braintreeGeneral');
const requestsHelper = require('../helpers/payPalRequestsHelperGlobal');

const loaderInstance = require('../../loaderHelper');

/**
 * PayPal base model constructor
 * @param {Object} alertHandlerModel alert handler model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {Object} $loaderContainer loader js container
 */
function PayPalBaseModel(
	alertHandlerModel,
	btClientInstancePromise,
	payPalButtonSelector,
	payPalConfigurations,
	$loaderContainer
) {
	this.isVaultMode = payPalConfigurations.options.flow !== 'checkout';
	this.requestBillingAgreement =
		Boolean(payPalConfigurations.options.requestBillingAgreement) &&
		this.isVaultMode;
	this.btClientInstancePromise = btClientInstancePromise;
	this.payPalButtonSelector = payPalButtonSelector;
	this.payPalConfigurations = payPalConfigurations;
	// Fraud data related
	this.isFraudToolsEnabled = payPalConfigurations.isFraudToolsEnabled;
	// Setup init fraudData value for default case (default case: fraud data disabled)
	this.fraudDataPromise = Promise.resolve({
		fraudDataCollectionDisabled: true,
	});

	// URLs
	this.basketDataUrl = payPalConfigurations.getOrderInfoUrl;
	this.submitPaymentUrl = payPalConfigurations.paypalHandle;
	this.placeOrderStageUrl = payPalConfigurations.redirectUrl;
	this.validateAddressesUrl = payPalConfigurations.validateAddressesUrl;
	this.updateShippingMethodsListUrl =
		payPalConfigurations.updateShippingMethodsListUrl;

	this.braintreePayPalSDK = braintreePayPalSDK.payPalButtonRendering;

	this.alertHandlerModel = alertHandlerModel;
	this.loader = loaderInstance($loaderContainer);

	this.basketData = null;
	this.error = null;
}

/* -------------------- Payment Callbacks -------------------- */

/**
 * Method triggers when buyer approved payment in PayPal modal and they passed succesfully
 * THIS METHOD NEEDS TO BE OVERRIDDEN in children class
 * @param {Object} payload buyer's data from PayPal
 * @param {boolean} isExpressCheckout true or false/undefined depending on whether it's EC or not
 * @returns {void} either updates paypalBaseModel error/placeOrderStageUrl properties or payload billing address
 */
PayPalBaseModel.prototype.onApprovePaymentCallback = function (
	payload,
	isExpressCheckout
) {
	// basic payload validation
	const ppDetails = payload.details;
	const ppShippingAddress = ppDetails.shippingAddress;
	const city = ppShippingAddress.city;
	const state = ppShippingAddress.state;
	const country = ppShippingAddress.countryCode;
	const postalCode = ppShippingAddress.postalCode;

	if (!ppDetails.billingAddress) {
		try {
			ppDetails.billingAddress = {
				city: city,
				countryCode: country,
				line1: ppShippingAddress.line1,
				line2: ppShippingAddress.line2 || '',
				postalCode: postalCode,
				state: state,
			};
		} catch (error) {
			this.alertHandlerModel.showBillingAddressIsNotSupportedError();
			this.error = error;
		}
	}

	if (isExpressCheckout) {
		const helper = require('../../helper');

		const paymentMethodId = window.braintreeConstants.PAYMENT_METHOD_ID_PAYPAL;
		const addressQueryString = `city=${city.replaceAll(
			' ',
			'%20'
		)}&stateCode=${state}&countryCode=${country}&postalCode=${postalCode}`;
		const url = `${window.braintreeUrls.getApplicableShippingOptions}?paymentMethodId=${paymentMethodId}&${addressQueryString}`;

		helper.getApplicableShippingOptions(url).then((data) => {
			let shippingOptions = data.shippingOptions;
			if (
				!shippingOptions.some(
					(shippingOption) => shippingOption.selected === true
				)
			) {
				this.placeOrderStageUrl = `${window.braintreeUrls.chooseShippingUrl}&errorId=error.shippingoption.invalid`;
			}
		});
	}
};

/**
 * Method triggers when buyer canceled payment in PayPal modal
 * THIS METHOD CAN BE OVERRIDDEN in children class
 */
PayPalBaseModel.prototype.onCancelPaymentCallback = function () {};

/**
 * Method triggers when buyer clicked on PayPal button and PayPal modal is opening
 * THIS METHOD CAN BE OVERRIDDEN in children class
 * @returns {Object} Amount and Shipping address from basket
 */
PayPalBaseModel.prototype.onOrderCreateCallback = async function () {
	// Hide custom error on buyer PayPal button click.
	this.alertHandlerModel.hideAlerts();

	// Get basket again in case if "onPayPalButtonClickCallback" wasn't triggered (Change Payment Method Button case)
	const basketData =
		this.basketData || (await requestsHelper.getBasketData(this.basketDataUrl));
	const shippingAddress = basketData.shippingAddress || this.savedPaypalAddress;

	const payPalConfigurations = this.payPalConfigurations;
	const mainPayPalConfigurations = payPalConfigurations.options;
	const requestBillingAgreement =
		mainPayPalConfigurations.requestBillingAgreement;

	const paymentObjectData = {
		amount: basketData.amount,
		currency: mainPayPalConfigurations.currency,
		flow: mainPayPalConfigurations.flow,
		intent: mainPayPalConfigurations.intent,
		locale: mainPayPalConfigurations.locale,
		enableShippingAddress: mainPayPalConfigurations.enableShippingAddress,
		billingAgreementDescription:
			mainPayPalConfigurations.billingAgreementDescription,
		displayName: mainPayPalConfigurations.displayName,
	};

	// "requestBillingAgreement" will be enabled or when Checkout with Vault is enabled
	// Vault mode should be also enabled
	if (requestBillingAgreement) {
		paymentObjectData.requestBillingAgreement = requestBillingAgreement;

		if (mainPayPalConfigurations.billingAgreementDetails) {
			paymentObjectData.billingAgreementDetails = {
				description:
					mainPayPalConfigurations.billingAgreementDetails.description,
			};
		}
	}

	// Case when process from PDP & Cart & Mini Cart & Checkout and basket isn't empty or we on Account page
	if (shippingAddress) {
		// shippingAddressEditable should be always false for ChangePaymentMehtod button, and true for smart PayPal button
		paymentObjectData.shippingAddressEditable =
			!payPalConfigurations.changePMButtonEnabled;
		paymentObjectData.shippingAddressOverride = {
			line1: shippingAddress.line1,
			line2: shippingAddress.line2,
			city: shippingAddress.city,
			state: shippingAddress.state,
			postalCode: shippingAddress.postalCode,
			countryCode: shippingAddress.countryCode,
			phone: shippingAddress.phone,
			recipientName: shippingAddress.recipientName,
		};
	}

	return paymentObjectData;
};

/**
 * Callback triggers when buyer clicked on PayPal button
 * @param {Object} _ first not used param
 * @param {Object} actions BT actions object
 */
// eslint-disable-next-line no-unused-vars
PayPalBaseModel.prototype.onPayPalButtonClickCallback = async function (
	fundingSourceResponse
) {
	// Hide custom error on buyer PayPal button click.
	this.alertHandlerModel.hideAlerts();
	this.basketData = this.basketDataUrl
		? await requestsHelper.getBasketData(this.basketDataUrl)
		: null;

	if (document.getElementById('braintreePaypalFundingSource')) {
		document.getElementById('braintreePaypalFundingSource').value =
			fundingSourceResponse.fundingSource;
	}
};

/**
 * Method triggers when error happens during PayPal modal payment processing
 * THIS METHOD CAN BE OVERRIDDEN in children class
 * @param {Object} error error object with "message" and "code" inside
 */
PayPalBaseModel.prototype.onErrorPaymentCallback = function (error) {
	this.alertHandlerModel.showBraintreeError(error);
};

/**
 * This method is executed when creating paypalCheckoutInstance. Should contain configs for "braintree.paypalCheckout.create" method.
 * Should only be used inside "braintreePayPalSDK" file
 * @param {Object} btClientInstance Braintree client instance
 * @returns {Object} object with additional configurations
 */
PayPalBaseModel.prototype.payPalCheckoutInstanceConfigs = function (
	btClientInstance
) {
	return {
		client: btClientInstance,
	};
};

/**
 * This method is executed when loading PayPal SDK
 * @returns {Object} object with Checkout mode PayPal SDK configs
 */
PayPalBaseModel.prototype.payPalCheckoutSdkConfigs = function () {
	const isChangePaymentButtonActive =
		this.payPalConfigurations.changePMButtonEnabled;
	const intent = this.payPalConfigurations.options.intent;
	const currency = this.payPalConfigurations.options.currency;
	const enableFundingList = this.payPalConfigurations.options.enableFundingList;
	let disableFundingList = this.payPalConfigurations.options.disableFundingList;
	const payPalSDKConfigObject = {
		intent: intent,
		currency: currency,
		components: 'buttons,messages',
		commit: false,
	};

	// Functionality for disabling Debit/Credit Card Button when Change Payment Method Button is enabled
	if (isChangePaymentButtonActive && disableFundingList !== undefined) {
		disableFundingList += ',card';
	} else if (isChangePaymentButtonActive && disableFundingList === undefined) {
		disableFundingList = 'card';
	}

	if (enableFundingList !== undefined) {
		payPalSDKConfigObject['enable-funding'] = enableFundingList;
	}

	if (disableFundingList !== undefined) {
		payPalSDKConfigObject['disable-funding'] = disableFundingList;
	}

	return payPalSDKConfigObject;
};

/**
 * This method is executed when loading PayPal SDK
 * @returns {Object} object with Vault mode PayPal SDK configs
 */
PayPalBaseModel.prototype.payPalVaultSdkConfigs = function () {
	const isChangePaymentButtonActive =
		this.payPalConfigurations.changePMButtonEnabled;
	const enableFundingList = this.payPalConfigurations.options.enableFundingList;
	let disableFundingList = this.payPalConfigurations.options.disableFundingList;

	// Functionality for disabling Debit/Credit Card Button when Change Payment Method Button is enabled
	if (isChangePaymentButtonActive && disableFundingList !== undefined) {
		disableFundingList += ',card';
	} else if (isChangePaymentButtonActive && disableFundingList === undefined) {
		disableFundingList = 'card';
	}

	const payPalVaultSdkConfigsObject = {
		vault: true,
		components: 'buttons,messages',
	};

	if (enableFundingList !== undefined) {
		payPalVaultSdkConfigsObject['enable-funding'] = enableFundingList;
	}

	if (disableFundingList !== undefined) {
		payPalVaultSdkConfigsObject['disable-funding'] = disableFundingList;
	}

	return payPalVaultSdkConfigsObject;
};

/* -------------------- Payment logic -------------------- */

/**
 * Function which should be called when we need to init PayPal payment button
 * @returns {Promise} PayPal button
 */
PayPalBaseModel.prototype.initPayment = function () {
	const CurrentPayPalPaymenProcessorModelInstance = this;

	return this.braintreePayPalSDK(
		this.btClientInstancePromise,
		this.isVaultMode,
		this.payPalConfigurations,
		[CurrentPayPalPaymenProcessorModelInstance]
	);
};

/**
 * Generate fraud data if enabled
 */
PayPalBaseModel.prototype.generateFraudData = function () {
	if (this.isFraudToolsEnabled) {
		this.fraudDataPromise = braintreeGeneral.collectFraudData(
			this.btClientInstancePromise
		);
	}
	// Don't need "else" case, since default value was inited in constructor
};

module.exports = PayPalBaseModel;

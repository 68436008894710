'use strict';

const helper = require('../../helper');
const loaderInstance = require('../../loaderHelper');
const paymentMethodGeneral = require('../../paymentMethodGeneral');

const $braintreeGooglePayLoader = document.querySelector(
	'.js-braintree-googlepay-loader'
);

// Global variables
let googlePayConfigs;
let googlePayBillingAddressData;
let googlePayShippingAddressData;
let googlePayNonce;
let googlepayPaymentType;
let googlePayCardDescription;
let googlepayCheckoutFormData;
let alertHandler;
let loader;

/**
 * Expends Google Pay checkout form with required data
 */
function expandGooglePayCheckoutFormData() {
	const deviceData = document.getElementById(
		'braintreeGooglePayDeviceData'
	).value;
	const $csrfToken = document.querySelector(
		'.js-braintree-cart-buttons-wrap #csrf_token'
	);

	googlepayCheckoutFormData.append($csrfToken.name, $csrfToken.value);
	googlepayCheckoutFormData.append('braintreeGooglePayNonce', googlePayNonce);
	googlepayCheckoutFormData.append('braintreeGooglePayDeviceData', deviceData);
	googlepayCheckoutFormData.append(
		'braintreeGooglePayCardDescription',
		googlePayCardDescription
	);
	googlepayCheckoutFormData.append(
		'braintreeGooglepayPaymentType',
		googlepayPaymentType
	);
	googlepayCheckoutFormData.append(
		'braintreeGooglePayShippingAddress',
		JSON.stringify(googlePayShippingAddressData) || '{}'
	);
	googlepayCheckoutFormData.append(
		'braintreeGooglePayBillingAddress',
		JSON.stringify(googlePayBillingAddressData) || '{}'
	);
}

/**
 * Process Google Pay on the Cart Page
 */
function processCartFlow() {
	const $braintreeCartButtons = document.querySelector(
		'.js-braintree-cart-buttons-wrap'
	);
	const placeOrderUrl = window.braintreeUrls.placeOrderUrl;
	const checkoutSubmitPayment = window.braintreeUrls.checkoutSubmitPayment;
	const checkoutFormFields = $braintreeCartButtons.getAttribute(
		'data-checkout-form-fields'
	);
	const paymentFieldsData = helper.getPaymentFieldsData(
		googlePayBillingAddressData,
		googlePayConfigs.paymentMethodName
	);

	// Creates and updates Google Pay checkout form for service side
	googlepayCheckoutFormData = helper.createPaymentFormData(
		checkoutFormFields,
		paymentFieldsData
	);
	googlepayCheckoutFormData.append('pageFlowCart', true);
	expandGooglePayCheckoutFormData();
	// submit customer form with email (CheckoutServices-SubmitCustomer)
	// as we skip step "login via guest/registered user" while express checkout
	// email is set only in case of guest checkout and if email is not already set
	paymentMethodGeneral.submitCustomerForm(googlePayBillingAddressData.email);

	fetch(helper.getUrlWithCsrfToken(checkoutSubmitPayment), {
		method: 'POST',
		body: googlepayCheckoutFormData,
	})
		.then((response) => response.json())
		.then((data) => {
			if (!helper.handleSubmitPaymentErrors(data, alertHandler)) {
				sessionStorage.setItem('pageState', 'cart');
				window.location.href = placeOrderUrl;
			}

			loader.hide();
		})
		.catch((error) => {
			if (error && error.redirectUrl) {
				window.location.href = error.redirectUrl;
			}
		});
}

/**
 * Inits Google Pay cart components
 * @param {Object} billingAddressData Billing address data object
 * @param {Object} shippingAddressData Shipping address data object
 * @param {string} nonce Payment method nonce
 * @param {string} paymentType Payment method type
 * @param {string} description Google Pay card description
 * @param {Constructor} alertHandlerModel Alert handler Model
 */
function init(
	billingAddressData,
	shippingAddressData,
	nonce,
	paymentType,
	description,
	alertHandlerModel
) {
	const $googlepayButton = document.querySelector(
		'.js-braintree-googlepay-button'
	);

	googlePayConfigs = helper.tryParseJSON(
		$googlepayButton.getAttribute('data-braintree-config')
	);
	googlePayBillingAddressData = billingAddressData;
	googlePayShippingAddressData = shippingAddressData;
	googlePayNonce = nonce;
	googlepayPaymentType = paymentType;
	googlePayCardDescription = description;
	loader = loaderInstance($braintreeGooglePayLoader);
	alertHandler = alertHandlerModel;

	processCartFlow();
}

module.exports = {
	init,
};

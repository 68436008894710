'use strict';

/**
 * Hide PayPal button
 */
function hidePayPalButtons() {
	const payPalButtonsContainer = document.querySelector(
		'.js-braintree-paypal-billing-button'
	);

	payPalButtonsContainer.classList.add('d-none');
}

/* -------------------------------------------- Account\Options Manipulations -------------------------------------------- */

/**
 * Hide account option from account dropdown
 * @param {Object} $accountOption vanilla js account option container
 */
function hideAccount($accountOption) {
	$accountOption.style.display = 'none';
}

/**
 * Hide Save New Account checkbox with corresponding block
 */
function hideSaveNewAccountContainer() {
	const savePaypalAccountCheckbox = document.getElementById(
		'braintreeSavePaypalAccount'
	);
	const savePaypalAccountContainer = document.getElementById(
		'braintreeSavePaypalAccountContainer'
	);

	savePaypalAccountCheckbox.value = false;
	savePaypalAccountContainer.style.display = 'none';
}

/**
 * Make all hidden stored paypal accounts visible for buyer
 */
function displayAllStoredAccounts() {
	const braintreePaypalAccountsList = document.getElementById(
		'braintreePaypalAccountsList'
	);

	Array.prototype.forEach.call(braintreePaypalAccountsList, function (el) {
		if (el.style.display === 'none') {
			el.style.display = 'block';
		}
	});
}

/**
 * Hides 'Save PayPal Account' checkbox on the Billing Page
 */
function hideSavePayPalAccountBlock() {
	const $savePaypalAccountCountainer = document.getElementById(
		'braintreeSavePaypalAccountContainer'
	);

	$savePaypalAccountCountainer.style.display = 'none';
}

/**
 * Function which store billing data on session payment option level
 * @param {Object} btPayloadDetails braintree payload details
 */
function addBillingAddressOnPaymentOptionLevel(btPayloadDetails) {
	const $sessionPyapalAccount = document.getElementById(
		'braintreePaypalAccount'
	);
	const btBillingAddress = btPayloadDetails.billingAddress;

	$sessionPyapalAccount.setAttribute(
		'data-billing-address',
		JSON.stringify({
			firstName: btPayloadDetails.firstName,
			lastName: btPayloadDetails.lastName,
			line1: btBillingAddress.line1,
			city: btBillingAddress.city,
			state: btBillingAddress.state,
			postalCode: decodeURIComponent(btBillingAddress.postalCode),
		})
	);
}

module.exports = {
	hidePayPalButtons,
	// Account\Options Manipulations
	hideAccount,
	hideSaveNewAccountContainer,
	displayAllStoredAccounts,
	hideSavePayPalAccountBlock,
	addBillingAddressOnPaymentOptionLevel,
};

'use strict';

/* global braintreeUtils braintree $ */

let btClientInstancePromise;
let venmoClientInstance;

/**
 * Checks whether browser supports Venmo
 * @returns {Promise} with isBrowserSupported value
 */
function isBrowserSupported() {
	return btClientInstancePromise.then(function (btClientInstance) {
		venmoClientInstance = btClientInstance;

		return braintree.venmo.isBrowserSupported({
			allowNewBrowserTab: false,
			allowDesktop: true,
		});
	});
}

/**
 * Creates Venmo instance
 * @returns {Promise} Promise resolved with Venmo instance
 */
function createVenmo() {
	return braintree.venmo.create({
		client: venmoClientInstance,
		allowNewBrowserTab: false,
		ignoreHistoryChanges: true,
		allowDesktop: true,
	});
}

/**
 * Tokenizes Venmo payment
 * @param {Object} venmoInstance Venmo instance
 * @returns {Promise} Promise resolved with tokenized payload
 */
function tokenize(venmoInstance) {
	return venmoInstance.tokenize({ processResultsDelay: 10 });
}

/**
 * Inits braintreeVenmoSDK with client instance promise
 * @param {Promise} braintreeClientInstancePromise Braintree Venmo client instance promise
 */
function init(braintreeClientInstancePromise) {
	btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
	init,
	isBrowserSupported,
	createVenmo,
	tokenize,
};

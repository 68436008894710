'use strict';

const helper = require('../../helper');

/**
 * Remove product from Cart
 */
function removeProductFromCart() {
	fetch(
		helper.getUrlWithCsrfToken(window.braintreeUrls.removeAllProductsFromCart)
	);
}

module.exports = {
	removeProductFromCart,
};

'use strict';

const helper = require('../../helper');
const venmoHelper = require('../helpers/venmoHelper');

const $braintreeVenmoSessionAccount = document.getElementById(
	'braintreeVenmoAccount'
);
const $braintreeVenmoUserId = document.getElementById('braintreeVenmoUserId');
const $venmoAccountBlock = document.querySelector(
	'.js-braintree-used-venmo-account'
);
const $venmoButton = document.querySelector('.js-braintree-venmo-button');

/**
 * Handles showing Venmo session account
 */
function showVenmoAccount() {
	const btVenmoAccountsList = document.getElementById(
		'braintreeVenmoAccountsList'
	);

	if (!btVenmoAccountsList) {
		$venmoAccountBlock.classList.remove('used-venmo-account-hide');
		$braintreeVenmoSessionAccount.options[0].text = $braintreeVenmoUserId.value;
	}

	$venmoButton.style.display = 'none';

	helper.continueButtonToggle(false);
}

/**
 * Removes method was used and change appearance of Venmo tab
 */
function removeSessionNonce() {
	const defaultOption = helper.getOptionByDataDefault(
		'#braintreeVenmoAccountsList'
	);
	const braintreeVenmoNonce = document.getElementById('braintreeVenmoNonce');
	const braintreeVenmoBilling = document.getElementById(
		'braintreeVenmoBilling'
	);

	if (defaultOption) {
		defaultOption.selected = true;

		venmoHelper.venmoContentTabHandler();
	} else {
		$venmoAccountBlock.classList.remove('js-used-venmo-account');
		$venmoAccountBlock.classList.add('used-venmo-account-hide');
		$venmoButton.style.display = 'block';
	}

	braintreeVenmoNonce.value = '';
	braintreeVenmoBilling.value = '';
}

module.exports = {
	showVenmoAccount,
	removeSessionNonce,
};

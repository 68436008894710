'use strict';

const srcHelper = require('../helpers/srcHelper');
const loaderInstance = require('../../loaderHelper');

// Models
const SrcCartModel = require('../models/srcCartModel');
const SrcAlertHandlerModel = require('../alerthandler/srcAlertHandlerModel');

const $braintreeCartSrcButton = document.querySelector(
	'.js-braintree-cart-src-button'
);

/**
 * Inits SRC account functionality on the Cart Page
 * @param {Promise} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
	const $braintreeSrcLoader = document.querySelector(
		'.js-braintree-src-loader'
	);
	const $totalAmount = document.querySelector('.grand-total');
	const srcLoader = loaderInstance($braintreeSrcLoader);
	const srcButtonConfigurations = srcHelper.getBraintreeSRCButtonConfigs();
	const $braintreeSrcDeviceDataInput = document.querySelector(
		'input[name=braintreeSrcDeviceDataInput]'
	);
	const alertHandler = new SrcAlertHandlerModel(
		srcButtonConfigurations.messages
	);

	const SrcCheckoutModelInstance = new SrcCartModel(
		clientInstancePromise,
		srcButtonConfigurations,
		$braintreeSrcDeviceDataInput,
		srcLoader,
		alertHandler
	);

	// Hides errors on the Cart Page
	$braintreeCartSrcButton.addEventListener('click', alertHandler.hideAlerts);

	SrcCheckoutModelInstance.initSrcButton();

	// Tracks cases when total amount equal zero and throw an error
	srcHelper.zeroAmountSrcButtonBehavior($totalAmount, alertHandler);
}

module.exports = {
	init,
};

'use strict';

const helper = require('../../helper');

/**
 * Save PayPal account to DW
 * @param {string} addPayPalAccountUrl add PayPal account url
 * @param {FormData} paymentFormData payment FormData
 * @returns {Promise} response from server
 */
function savePayPalAccount(addPayPalAccountUrl, paymentFormData) {
	return fetch(helper.getUrlWithCsrfToken(addPayPalAccountUrl), {
		method: 'POST',
		body: btoa(JSON.stringify(Object.fromEntries(paymentFormData))),
		headers: { 'Content-Type': 'application/json' },
	});
}

/**
 * Render PayPal account
 * @param {string} renderAccountsUrl render PayPal accounts URL
 * @returns {Promise} promise with html of PayPal account list
 */
function renderPayPalAccounts(renderAccountsUrl) {
	return fetch(renderAccountsUrl);
}

/**
 * Hide PayPal account button
 */
function hidePaypalAccountBtn() {
	const $addNewPaypalAccountBtn = document.querySelector(
		'.js-add-paypal-account'
	);
	const $paypalAccountButtonWrapper = document.querySelector(
		'.js-braintree-account-paypal-button-wrapper'
	);

	// Display add new paypal account button
	$addNewPaypalAccountBtn.classList.remove('d-none');
	// Hide PayPal Account button
	$paypalAccountButtonWrapper.classList.add('d-none');
}

/**
 * Add PayPal accounts html template to the DOM
 * @param {string} accountListTemplate html template with PayPal account list
 */
function addPayPalAccountsToTheTemplate(accountListTemplate) {
	const $payPalAccountsContainer = document.querySelector(
		'.js-paypal-accounts'
	);

	$payPalAccountsContainer.innerHTML = accountListTemplate;
}

/**
 * Trigger cart:updates event
 */
function throwCartUpdate() {
	$('body').trigger('cart:update');
}

module.exports = {
	savePayPalAccount,
	renderPayPalAccounts,
	hidePaypalAccountBtn,
	addPayPalAccountsToTheTemplate,
	throwCartUpdate,
};
